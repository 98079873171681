import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { get, ref } from "firebase/database";
import { database } from "../firebaseConfig";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Mapbox styles
import "./DriverPage.css";
import { useLocation } from "react-router-dom";
import AddCard from "../components/AddCard";
import Notification from "../components/Notification";
import { getPaymentMethodList, getCustomer, getCashBalance, getPaymentMethod, setDefault, removeCard } from "../request";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const apiToken = process.env.REACT_APP_MAPBOX_TOKEN;
const stripeKey = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripeKey);



const savedAddresses = [];



export default function RidersDashboard() {
    const { user, logout } = useAuth();
    const [customer, setCustomer] = useState(null)
    const [defaultCard, setDefaultCard] = useState(null)
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [driverDetails, setDriverDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userLocation, setUserLocation] = useState(null); // Store user's coordinates
    const [addingCard, setAddingCard] = useState(false)
    const [listPaymentMethods, setListPaymentMethods] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const [showNavBar, setShowNavBar] = useState(false)
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState("Driver/Dashboard")


    useEffect(() => {

        const resize = () => {
            setWidth(window.innerWidth);
        };

        // Add event listener
        window.addEventListener('resize', resize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);


    useEffect(() => {
        console.log(location.pathname);
        setCurrentPage(location.pathname)
    }, [location])


    const [viewport, setViewport] = useState({
        latitude: 37.7749, // Default to San Francisco
        longitude: -122.4194,
        zoom: 12,
    });

    useEffect(() => {
        if (!user) {
            navigate("/login"); // Redirect if not logged in
            return;
        }
    }, [user])

    const [cashBalance, setCashBalance] = useState(0)

    async function refreshCashBalance() {
        console.log(user);
        const gettin = await getCashBalance(user.rider.stripeCustomerId)
        if (gettin.success) {
            const a = gettin.cashBalance
            setCashBalance(a)
        }
    }

    useEffect(() => {
        if (!user) return
        refreshCashBalance()
    }, [user])

    const [timer, setTimer] = useState(null)

    const triggerNotification = (text) => {
        clearTimeout(timer)
        setMessage(text);
        const t = setTimeout(() => {
            setMessage(""); // Reset the message after it disappears
            clearTimeout(timer)
        }, 10000); // Notification disappears after 3 seconds

        setTimer(t)
    };



    useEffect(() => {
        if (!user) return

        const fetchDriverDetails = async () => {
            try {
                const snapshot = await get(ref(database, `users/${user.rider.uid}/rider`));
                if (snapshot.exists()) {
                    const data = snapshot.val()

                    const customerId = data.stripeCustomerId

                    const customer = await getCustomer(customerId)

                    if (customer && customer.customer) {

                        if (customer.customer.invoice_settings
                            && customer.customer.invoice_settings.default_payment_method) {
                            const paymentMethod = await getPaymentMethod(customer.customer.invoice_settings.default_payment_method)

                            if (paymentMethod && paymentMethod.paymentMethod && paymentMethod.paymentMethod.type === "card") {
                                const card = paymentMethod.paymentMethod
                                setDefaultCard(card)
                            }


                        }
                        setCustomer(customer.customer)
                        setDriverDetails(data)
                    }

                } else {
                    console.error("No driver data found!");
                }
            } catch (error) {
                console.error("Error fetching driver data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDriverDetails();
    }, [user, navigate, addingCard, listPaymentMethods]);




    useEffect(() => {
        // Fetch user's location on load
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([longitude, latitude]);
                    setViewport((prev) => ({
                        ...prev,
                        latitude,
                        longitude,
                    }));
                },
                (error) => {
                    console.error("Error fetching user location:", error);
                }
            );
        } else {
            console.error("Geolocation is not available");
        }
    }, []);








    if (loading)
        return (
            <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span className="loading-indicator" />
            </div>
        );

    if (!driverDetails)
        return (
            <div className="error">
                <h2>No driver details found!</h2>
                <p>Please contact support or update your profile.</p>
            </div>
        );


    return (
        <>

            <div style={{ display: "flex" }}>
                {<div className={`nav-bar-container ${showNavBar ? "open" : ""}`}>
                    <div className="nav-bar close">
                        {/* Profile Section */}
                        <div className="nav-section">
                            <div className="nav-profile">
                                <img
                                    src={driverDetails.profilePicture || "/pf.jpg"}
                                    style={{ aspectRatio: 1, borderRadius: "100%" }}
                                    alt="profile_image"
                                />
                                <div className="info" style={{ display: "flex", alignItems: "center" }}>
                                    <p>
                                        {driverDetails.firstName} {driverDetails.lastName}
                                    </p>
                                </div>
                            </div>
                            <ul className="nav-links">
                                <li>
                                    <Link to="/rider/edit-profile">
                                        <ion-icon name="pencil-outline" />
                                        <span>Edit Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/change-password">
                                        <ion-icon name="lock-closed-outline" />
                                        <span>Change Password</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="nav-section">
                            <hr />
                            <ul className="nav-links">
                                <li>
                                    <Link to="/rider/account">
                                        <ion-icon name="grid-outline"></ion-icon>
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                            </ul>

                        </div>


                        {/* Other Actions */}
                        <div className="nav-section">
                            <hr />
                            <ul className="nav-links">
                                {/* <li>
                                    <Link to="/rider/settings">
                                        <ion-icon name="settings-outline" />
                                        <span>Settings</span>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/rider/help">
                                        <ion-icon name="help-circle-outline" />
                                        <span>Help & Support</span>
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={logout} className="logout-button stop">
                                        <ion-icon name="log-out-outline" />
                                        <span>Logout</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>}

                <div style={{ flex: 1 }}>

                    <div className="dashboard-header">
                        {width > 678 && <div> {currentPage}</div>}

                        {width > 678 && <label htmlFor="search-input" className="searchbox">
                            <ion-icon name="search"></ion-icon>
                            <input id="search-input" onFocus={(e) => { e.target.labels[0].classList.add("focus") }} onBlur={(e) => { e.target.labels[0].classList.remove("focus") }} type="text" placeholder="Search Dashboard" />
                        </label>}

                        {width < 678 && <span onClick={() => {
                            setShowNavBar(!showNavBar)
                        }}>
                            <ion-icon name={showNavBar ? "close" : "menu"} style={{ fontSize: "24px" }}></ion-icon>
                        </span>}

                        <div style={{ display: "flex", alignItems: "center" }}>
                            {<button onClick={() => navigate("/")}>Home</button>}

                        </div>
                    </div>

                    <div className="driver-dashboard">


                        <div style={{ display: "flex", gap: "10px", flexDirection: width > 678 ? "row" : "column" }}>
                            <section className="dashboard-section">
                                <b>Account Details</b>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "20px" }}>
                                    <div>
                                        <Item name="FULL NAME" value={`${driverDetails.firstName} ${driverDetails.lastName}`} />
                                        <Item name="Current Balance" value={"$" + (((cashBalance?.available?.usd || 0) * 0.01).toFixed(2))} />

                                        <div style={{ margin: "-10px", marginTop: "auto", display: "flex" }}>
                                            <button>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </section>



                            <section className="dashboard-section">
                                <b>Recent Trips</b>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {savedAddresses.map((trip, index) => (
                                        <div key={index} className="list-item" >
                                            <p>{trip.name}</p>
                                            <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                -$4.98
                                            </span>
                                        </div>
                                    ))}
                                </div>


                            </section>

                        </div>



                        <div style={{ display: "flex", gap: "10px", flexDirection: width > 678 ? "row" : "column" }}>
                            <section className="dashboard-section">
                                <b style={{ display: "flex", marginBottom: "20px", alignItems: "center", gap: "10px" }}>Payment Methods <span style={{ backgroundColor: "var(--header-color)", color: "#fff", padding: '2px 5px', borderRadius: '2px', fontSize: "12px" }}>Default</span></b>
                                <div style={{ display: "flex", gap: "10px", flexDirection: width > 678 ? "row" : "column", perspective: "1000px" }}>
                                    {defaultCard && <PaymentMethod isDefault paymentMethod={defaultCard} onClick={() => setListPaymentMethods(true)} />}
                                    <div className="card-item" onClick={() => setAddingCard(true)} style={{ aspectRatio: 1, display: "flex", borderStyle: "dashed" }}>
                                        <ion-icon name="add-outline" style={{ fontSize: "32px", margin: "auto" }}></ion-icon>
                                    </div>
                                </div>
                            </section>

                            <section className="dashboard-section" style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px" }}>
                                <Map
                                    mapboxAccessToken={apiToken}
                                    {...viewport} // Spread the updated viewport state
                                    onMove={(evt) => setViewport(evt.viewState)}
                                    style={{ width: "100%", height: "100%", borderRadius: "10px" }}
                                    mapStyle="mapbox://styles/mapbox/streets-v11"
                                >
                                    {/* Marker for User's Location */}
                                    {userLocation && (
                                        <Marker
                                            longitude={userLocation[0]}
                                            latitude={userLocation[1]}
                                            anchor="bottom"
                                        >
                                            <img
                                                src="https://docs.mapbox.com/help/demos/custom-markers-gl-js/mapbox-icon.png"
                                                alt="User Location"
                                                style={{ height: "30px", width: "30px" }}
                                            />
                                        </Marker>
                                    )}
                                </Map>

                            </section>
                        </div>
                    </div>
                </div>
                {addingCard &&
                    <Elements stripe={stripePromise}>
                        <AddCard onClose={() => setAddingCard(false)} />
                    </Elements>
                }
                {listPaymentMethods && customer && <PaymentMethodsList defaultCard={defaultCard} customerId={customer.id} onClose={() => setListPaymentMethods(false)} onSetDefault={() => setListPaymentMethods(false)} />}
                <Notification message={message} duration={3000} />
            </div>
        </>
    );
}



const PaymentMethod = ({ card, isDefault = false, paymentMethod, deletable = false, small = false, onRemove = () => { }, onClick = () => { } }) => {
    const cardRef = useRef(null);
    const lightRef = useRef(null);

    if (paymentMethod) {
        card = paymentMethod.card
    }

    const cardBrandColors = {
        visa: "#1A1F71",          // Visa Blue
        mastercard: "#EA001B",    // MasterCard Red
        amex: "#007BC1",          // American Express Blue
        discover: "#FF6000",      // Discover Orange
        diners: "#0061A8",        // Diners Club Blue
        jcb: "#000000",           // JCB Black
        unionpay: "#D52121",      // UnionPay Red
        default: "#808080"        // Default Gray for unknown brands
    };

    useEffect(() => {
        const card = cardRef.current;
        const light = lightRef.current;

        if (card && light) {
            const onMouseMove = (e) => {
                // Get card bounds
                const rect = card.getBoundingClientRect();
                const x = e.clientX - rect.left; // Mouse X position relative to the card
                const y = e.clientY - rect.top;  // Mouse Y position relative to the card

                // Calculate rotation angles
                const degree = 45
                const xRot = ((y / rect.height) - 0.5) * degree; // Rotate along X-axis
                const yRot = ((x / rect.width) - 0.5) * -(degree); // Rotate along Y-axis

                // Apply transform to the card
                card.style.transform = `rotateX(${xRot}deg) rotateY(${yRot}deg)`;

                // Move the light
                light.style.left = `${x}px`;
                light.style.top = `${y}px`;
            };

            const onMouseLeave = () => {
                // Reset transform and light position
                card.style.transform = `rotateX(0deg) rotateY(0deg)`;
                // light.style.left = `50%`;
                // light.style.top = `50%`;
            };

            // Attach event listeners
            card.addEventListener("mousemove", onMouseMove);
            card.addEventListener("mouseleave", onMouseLeave);

            // Cleanup listeners on unmount
            return () => {
                card.removeEventListener("mousemove", onMouseMove);
                card.removeEventListener("mouseleave", onMouseLeave);
            };
        }
    }, []);

    return (
        <li
            key={card.id}
            ref={cardRef}
            onClick={() => onClick(paymentMethod)}
            className="card-item c-card"
            style={{
                backgroundColor: cardBrandColors[card.brand] || "black",
                color: "white",
                position: "relative", // Ensure positioning for light
                overflow: "hidden",
                height: small ? "50px" : "120px",
                aspectRatio: 4 / 2,
                userSelect: "none",
                WebkitUserSelect: "none",
                cursor: "pointer"
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {card.brand && <div className="card-brand" style={{ fontSize: small ? "14px" : "18px" }}>{card.brand}</div>}
                {!isDefault && deletable && <span className="hoverHighlight" style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "20px", padding: "5px" }} onClick={(e) => {
                    e.stopPropagation()
                    onRemove(paymentMethod)
                }}>
                    <ion-icon name="trash-outline"></ion-icon>
                </span>}
            </div>

            {card.last4 && <div className="card-number" style={{ fontSize: small ? "8px" : "16px", letterSpacing: small ? "3px" : "5px" }}>**** **** **** {card.last4}</div>}
            {card.exp_month && <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <div className="card-holder" style={{ fontWeight: "bold", fontSize: small ? "8px" : "12px" }}>{isDefault ? "Default" : ""}</div>
                <div className="card-expiry">VALID THRU <br /> <span style={{ fontWeight: "bold" }}>{card.exp_month}/{String(card.exp_year).slice(-2)}</span></div>
            </div>}

            <div
                ref={lightRef}
                className="light"
                style={{
                    position: "absolute",
                    // background: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    pointerEvents: "none", // Ensure light doesn't interfere with pointer events
                    transform: "translate(-50%, -50%)", // Center the light
                    transition: "opacity 0.3s ease", // Smooth light disappearance
                }}
            />
        </li>
    );
};


function Item({ name, value }) {

    return (
        <p>
            <span style={{ fontSize: "10px" }}>{name}</span>
            <br />
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                {value}
            </span>
        </p>
    )
}



function PaymentMethodsList({ defaultCard, customerId, onClose = () => { }, onRemove = () => { }, onSetDefault = () => { } }) {

    const [paymentMethods, setPaymentMethods] = useState([])
    const [updated, setUpdated] = useState(0)

    useEffect(() => {
        if (!customerId) return
        const a = async () => {
            const response = await getPaymentMethodList(customerId)
            const paymentMethods = response.paymentMethods

            setPaymentMethods(paymentMethods || []);
        }

        a()

    }, [customerId, updated])

    const handleClick = async (paymentMethod) => {
        await setDefault(paymentMethod.customer, paymentMethod.id)
        onSetDefault()
    }

    const handleRemove = async (paymentMethod) => {
        console.log(paymentMethod);
        await removeCard(paymentMethod.id)
        setUpdated(Date.now())
    }

    return (
        <div className="modal-container" onClick={onClose}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
                <div className="header">
                    <b>List of Payment Methods</b>
                    <span onClick={onClose}><ion-icon name="close-outline"></ion-icon></span>
                </div>
                <div style={{
                    overflow: "auto",
                    maxHeight: "60vh",
                    margin: "10px",
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                    gap: "20px",
                    padding: "20px",
                    perspective: "500px"
                }}>
                    {paymentMethods.map((paymentMethod, index) => (
                        <PaymentMethod
                            isDefault={defaultCard.id === paymentMethod.id}
                            key={index}
                            deletable
                            small
                            paymentMethod={paymentMethod}
                            onRemove={handleRemove}
                            onClick={handleClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
