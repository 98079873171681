import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import './Admin.css'

const serverUrl = process.env.REACT_APP_SERVER_URL;


const Admin = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            const database = getDatabase();
            const usersRef = ref(database, 'users/');

            onValue(usersRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    // Filter users with "pending" status
                    const pendingUsers = Object.entries(data)
                        .filter(([_, user]) => user.driver && user.driver.status === 'pending')
                        .map(([uid, user]) => ({ uid, ...user.driver }));
                    setUsers(pendingUsers);
                }
                setLoading(false);
            });
        };

        fetchUsers();
    }, []);

    const handleUserAction = async (uid, action) => {
        // Find the user data based on the user ID
        const user = users.find(u => u.uid === uid);

        if (!user) {
            console.error("User not found.");
            return;
        }

        // Destructure the required fields from the user object
        const { ssn, routingNumber, dob, accountNumber, email, firstName, lastName } = user;
        const data = {
            action,
            uid,
            ssn,
            dob,
            routingNumber,
            accountNumber,
            email,
            firstName,
            lastName
        };

        try {
            // Sending a POST request with the user data
            const response = await fetch(`${serverUrl}/verifyUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",  // Content type is JSON
                },
                body: JSON.stringify(data),  // Stringify the user data before sending it
            });

            // Check for a successful response
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            // Parse the JSON response from the server
            const result = await response.json();
            console.log(result); // Log the result to check the response from the server
            return result; // Return the result if needed for further processing
        } catch (error) {
            console.error("Error:", error); // Log the error if there is an issue
            return error; // Return the error object
        }
    };


    if (loading) {
        return <p>Loading users...</p>;
    }

    return (
        <>
            <div className="header-background" style={{ margin: "0px" }}></div>

            <div className='adminPage'>
                <h1>Admin User Verification</h1>
                {users.length === 0 ? (
                    <p>No users pending verification.</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.uid}>
                                    <td>{`${user.firstName} ${user.lastName}`}</td>
                                    <td>{user.email}</td>
                                    <td>{user.status}</td>
                                    <td>
                                        <button onClick={() => handleUserAction(user.uid, 'approved')}>Approve</button>
                                        <button onClick={() => handleUserAction(user.uid, 'rejected')}>Reject</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
};

export default Admin;
