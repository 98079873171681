import React, { useEffect, useState } from "react";
import "./Notification.css";

const Notification = ({ message, duration = 10000, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setIsVisible(true);
            setTimeout(() => {
                setIsVisible(false);
                if (onClose) onClose(); // Callback after closing
            }, duration);
        }
    }, [message, duration, onClose]);

    return (
        isVisible && (
            <div className="notification">
                <span>{message}</span>
            </div>
        )
    );
};

export default Notification;
