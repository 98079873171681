import React, { useState } from "react";
import { updatePassword } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Import your Firebase auth instance
import { useNavigate } from "react-router";
import "./ChangePassword.css"

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState(""); // Optional: For re-authentication
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const navigate = useNavigate()

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError("Passwords do not match!");
            return;
        }

        try {
            const user = auth.currentUser;
            if (!user) {
                setError("No authenticated user found!");
                return;
            }

            // Update the password
            await updatePassword(user, newPassword);
            setSuccess("Password updated successfully!");
            setError("");
            setNewPassword("");
            setConfirmPassword("");
        } catch (err) {
            setError(err.message);
            setSuccess("");
        }
    };

    return (
        <div className="change-password-container ">
            <h2>Change Password</h2>
            <form onSubmit={handleChangePassword}>
                <div style={{ marginBottom: "10px" }}>
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        style={{ width: "100%", padding: "8px" }}
                    />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        style={{ width: "100%", padding: "8px" }}
                    />
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
                {success && <p style={{ color: "green" }}>{success}</p>}
                <button type="submit" style={{ padding: "10px 15px", cursor: "pointer" }}>
                    Change Password
                </button>
                <button onClick={(e) => {
                    e.preventDefault()
                    navigate(-1)
                }} style={{ padding: "10px 15px", cursor: "pointer", backgroundColor: "transparent", color: "var(--header-color)", marginTop: "10px" }}>
                    Back
                </button>
            </form>
        </div>
    );
}

export default ChangePassword;
