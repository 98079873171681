import React, { useEffect, useState } from "react";
import "./Header.css"; // Include styles
import { Link, useNavigate, useLocation } from "react-router-dom"; // Correct import for Link
import { useAuth } from "../AuthContext";
import { createCustomer } from "../request";

const Header = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const { user, setUser } = useAuth();

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {

        const resize = () => {
            setWidth(window.innerWidth);
            setOpen(false)
        };

        // Add event listener
        window.addEventListener('resize', resize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        setOpen(false)
    }, [location])


    useEffect(() => {
        if (user && !user.rider && user.driver) {
            (async () => {
                const { customer } = await createCustomer(user.driver.uid, {
                    email: user.driver.email,
                    firstName: user.driver.firstName,
                    lastName: user.driver.lastName
                })
                setUser(customer)
                console.log(customer);
            })()

        }
    }, [user])

    const [scrollOpacity, setScrollOpacity] = useState(0) // up to 255

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop; // Current scroll position
            const maxScroll = 300 //document.documentElement.scrollHeight - window.innerHeight; // Max scroll height
            const opacity = Math.min(255, (scrollTop / maxScroll) * 255); // Normalize to 0–255
            setScrollOpacity(opacity);
        };

        document.addEventListener("scroll", handleScroll);

        // Cleanup function to remove the listener
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header className="header" style={{ backgroundColor: location.pathname === "/" ? `rgba(255, 255, 255, ${scrollOpacity / 255})` : "#fff" }}>
            {/* <h1 className="header-title">DPI</h1> */}
            <img style={{ height: "50px", borderRadius: '10px' }} alt="" src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1732588874637x697593211426873200%2F_DPI%2520Logo%25201%2520%25281%2529.png?w=96&h=96&auto=compress&dpr=2&fit=max" />
            {width > 678 ?
                <nav className="header-nav">
                    <ul className="nav-list">
                        <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
                        <li className="nav-item"><Link to="/about" className="nav-link">About Us</Link></li>
                        <li className="nav-item"><Link to="/rider" className="nav-link">Rider</Link></li>
                        <li className="nav-item"><Link to="/driver" className="nav-link">Driver</Link></li>

                        {/* Conditional links based on user state */}
                        {!user && (
                            <li className="nav-item button-primary" style={{ marginLeft: "2em" }}>
                                <Link to="/login" className="nav-link">Login</Link>
                            </li>
                        )}
                        {user?.rider && (
                            <li className="nav-item button-primary" style={{ marginLeft: "2em" }}>
                                <Link to={`/rider/account`} className="nav-link">Account</Link>
                            </li>
                        )}
                        {user?.userType === "driver" && (
                            <li className="nav-item button-primary" style={{ marginLeft: "2em" }}>
                                <Link to={`/driver/dashboard`} className="nav-link">Dashboard</Link>
                            </li>
                        )}
                    </ul>
                </nav> :
                <>
                    <button onClick={() => setOpen(true)} style={{ margin: "0px", marginLeft: "auto", background: "transparent", padding: "0px" }}>
                        <ion-icon name="menu" style={{ color: "black", fontSize: "32px" }}></ion-icon>
                    </button>

                    {open && <div className="mobile-nav">
                        <nav className="header-nav">
                            <img style={{ margin: "0rem 0px 5rem 0px", height: "75px" }} alt="" src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1732588874637x697593211426873200%2F_DPI%2520Logo%25201%2520%25281%2529.png?w=96&h=96&auto=compress&dpr=2&fit=max" />

                            <ul className="nav-list">

                                <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
                                <li className="nav-item"><Link to="/about" className="nav-link">About Us</Link></li>
                                <li className="nav-item"><Link to="/rider" className="nav-link">Rider</Link></li>
                                <li className="nav-item"><Link to="/driver" className="nav-link">Driver</Link></li>

                                {/* Conditional links based on user state */}
                                {!user && (
                                    <li className="nav-item button-primary">
                                        <Link to="/login" className="nav-link">Login</Link>
                                    </li>
                                )}
                                {user?.rider && (
                                    <li className="nav-item button-primary">
                                        <Link to={`/rider/account`} className="nav-link">Account</Link>
                                    </li>
                                )}
                                {user?.userType === "driver" && (
                                    <li className="nav-item button-primary">
                                        <Link to={`/driver/dashboard`} className="nav-link">Dashboard</Link>
                                    </li>
                                )}
                            </ul>
                            <button onClick={() => setOpen(false)} style={{ margin: "5rem 0px 0rem 0px", borderRadius: "100px", marginLeft: "auto", padding: "8px 10px" }}>
                                <ion-icon name="close" style={{ color: "white", fontSize: "32px" }}></ion-icon>
                            </button>
                        </nav>
                    </div>}
                </>
            }
        </header>
    );
};

export default Header;
