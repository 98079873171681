// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database"
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCzu2bNe6kzZTcGipeBD1mytD-4sEaKJUw",
    authDomain: "dpiagpuf.firebaseapp.com",
    projectId: "dpiagpuf",
    storageBucket: "dpiagpuf.firebasestorage.app",
    messagingSenderId: "1093742425986",
    appId: "1:1093742425986:web:6fe086edd04cb9f44b419b",
    measurementId: "G-4SCJJTQ4DQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const database = getDatabase(app)
export const storage = getStorage(app)
export const analytics = getAnalytics(app);
