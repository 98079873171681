const serverUrl = process.env.REACT_APP_SERVER_URL;

export const createCustomer = async (uid, formData) => {
    const url = `${serverUrl}/rider_create`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid, ...formData }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data; // Return the token for further use
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};


export const submitDriverForm = async (uid) => {
    const url = `${serverUrl}/driver_request`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data) {
            return data;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};

export const proccessDriverForm = async (formData) => {
    const url = `${serverUrl}/driver_create`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data) {
            return data;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};



export const updateProfileForm = async (profileType, formData) => {
    const url = `${serverUrl}/profile_update`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ profileType, formData }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data) {
            return data;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};



export const beAvailable = async (status, uid, location) => {
    const url = `${serverUrl}/be_available`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status, uid, location }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};



export const getOffers = async (uid, customerId, distance, duration) => {
    const url = `${serverUrl}/get_offers`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid, customerId, distance, duration }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};



export const findDriver = async (offer) => {
    const url = `${serverUrl}/send_offer`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(offer), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            // console.error('Error:', errorData.error);
            return errorData; // Or handle error appropriately
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error:", error);
        return error; // Return null in case of network or other errors
    }
};



export const cancelSearching = async (riderId, offerId) => {
    const url = `${serverUrl}/cancel_offer`;

    console.log(riderId, offerId);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ riderId, offerId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};





export const acceptRequest = async (driver) => {
    const url = `${serverUrl}/accept_offer`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(driver), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};




export const pickingUpOffer = async (driver) => {
    const url = `${serverUrl}/picking_up_offer`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(driver), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};


export const updateDriverLocation = async (driver) => {
    const url = `${serverUrl}/update_driver_location`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(driver), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};






export const uploadImage = async (pathTo, file) => {

    if (!file) {
        console.error("No file selected!");
        return;
    }

    // Create FormData object
    const formData = new FormData();
    formData.append("url", pathTo);
    formData.append("file", file);

    try {
        const response = await fetch(`${serverUrl}/upload_image`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        console.log(result);
        const downloadURL = result.downloadURL
        return downloadURL
    } catch (error) {
        console.error("Upload Error:", error);
        return error
    }
};



export const getCustomer = async (customerId) => {
    const url = `${serverUrl}/get-customer`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customerId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};




export const getPaymentMethod = async (paymentMethodId) => {
    const url = `${serverUrl}/get-paymentMethod`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentMethodId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        if (data.success) {
            return data;
        } else {
            console.error("Error submitting form:", data.error);
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};




export const getPaymentMethodList = async (customerId) => {
    const url = `${serverUrl}/list-cards`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customerId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};


export const setDefault = async (customerId, paymentMethodId) => {
    const url = `${serverUrl}/set-default-card`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentMethodId, customerId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};


export const removeCard = async (paymentMethodId) => {
    const url = `${serverUrl}/remove-card`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentMethodId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};



export const getCashBalance = async (paymentMethodId) => {
    const url = `${serverUrl}/get-cash-balance`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentMethodId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData.error);
            return null; // Or handle error appropriately
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of network or other errors
    }
};




export const getBalance = async (accountId, driverId) => {
    const url = `${serverUrl}/get-balance`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId, driverId }), // Send the form data as JSON
        });

        if (!response.ok) {
            const errorData = await response.json();
            // console.error('Error:', errorData.error);
            return errorData.error; // Or handle error appropriately
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        return error; // Return null in case of network or other errors
    }
};





export const setupAccount = async (formData) => {
    const url = `${serverUrl}/setup_account`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
        });

        const result = await response.json();

        if (result.success) {
            return result;
        } else {
            console.error(`Error: ${result.error}`);
            return result
        }
    } catch (err) {
        console.error("Error submitting form:", err);
        return err
    }
};



export const acceptOffer = async (formData) => {
    const url = `${serverUrl}/accept_offer`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
        });

        const result = await response.json();

        if (result.success) {
            return result;
        } else {
            console.error(`Error: ${result.error}`);
            return result
        }
    } catch (err) {
        console.error("Error submitting form:", err);
        return err
    }
};


export const driverWaiting = async (formData) => {
    const url = `${serverUrl}/driver_waiting`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
        });

        const result = await response.json();

        if (result.success) {
            return result;
        } else {
            console.error(`Error: ${result.error}`);
            return result
        }
    } catch (err) {
        console.error("Error submitting form:", err);
        return err
    }
};

export const startTrip = async (formData) => {
    const url = `${serverUrl}/start_trip`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
        });

        const result = await response.json();

        if (result.success) {
            return result;
        } else {
            console.error(`Error: ${result.error}`);
            return result
        }
    } catch (err) {
        console.error("Error submitting form:", err);
        return err
    }
};


export const finishRide = async (formData) => {
    const url = `${serverUrl}/finish_ride`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
        });

        const result = await response.json();

        if (result.success) {
            return result;
        } else {
            console.error(`Error: ${result.error}`);
            return result
        }
    } catch (err) {
        console.error("Error submitting form:", err);
        return err
    }
};



export const closeOffer = async (formData) => {
    const url = `${serverUrl}/close_offer`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
        });

        const result = await response.json();

        if (result.success) {
            return result;
        } else {
            console.error(`Error: ${result.error}`);
            return result
        }
    } catch (err) {
        console.error("Error submitting form:", err);
        return err
    }
};


export const getPaymentsList = async (accountId) => {
    const url = `${serverUrl}/get_payments_list`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId }), // Send the form data as JSON
        });

        const result = await response.json();

        if (result.success) {
            return result;
        } else {
            console.error(`Error: ${result.error}`);
            return result
        }
    } catch (err) {
        console.error("Error submitting form:", err);
        return err
    }
};