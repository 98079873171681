import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css"; // Optional: for custom styles

const apiToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Map = () => {
    const mapContainerRef = useRef(null);
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        // Set your Mapbox access token
        mapboxgl.accessToken = apiToken;

        // Get user's location
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([longitude, latitude]);
                },
                (error) => {
                    console.error("Error fetching user location:", error);
                    setUserLocation([-74.006, 40.7128]); // Default to NYC if location fails
                }
            );
        } else {
            console.error("Geolocation is not available");
            setUserLocation([-74.006, 40.7128]); // Default to NYC
        }
    }, []);

    useEffect(() => {
        if (!userLocation) return;

        // Initialize the map after user location is fetched
        const map = new mapboxgl.Map({
            container: mapContainerRef.current, // Container ID
            style: 'mapbox://styles/diasporapower12/cm3un2y1k00ik01rw48tg7xkq', // Map style
            center: userLocation, // Center on user's location
            zoom: 18, // Initial zoom level
            pitch: 75, // Initial pitch for 3D effect
            bearing: 0, // Initial bearing
        });

        // Add a marker for the user's location
        new mapboxgl.Marker()
            .setLngLat(userLocation)
            .setPopup(new mapboxgl.Popup().setText("You are here")) // Optional: Add a popup
            .addTo(map);

        // Rotate the map continuously
        let bearing = 0;
        let pitch = 75; // Keep a consistent 3D pitch

        const rotateMap = () => {
            bearing = (bearing + 0.2) % 360; // Increment bearing and reset after 360
            pitch = 75 + 15 * Math.sin(bearing * (Math.PI / 180)); // Add slight pitch oscillation
            map.easeTo({ bearing, pitch, duration: 50 }); // Smoothly update bearing and pitch
            requestAnimationFrame(rotateMap); // Continue animation
        };

        map.on("load", () => {
            rotateMap(); // Start rotating after map loads
        });

        // Cleanup on unmount
        return () => map.remove();
    }, [userLocation]);

    return <div ref={mapContainerRef} className="map-container" />;
};

export default Map;
