import React from "react";
import "./About.css"; // Import the corresponding CSS file

const About = () => {
    return (
        <>
            {/* <div className="header-background" style={{ margin: "0px" }}></div> */}

            <div className="about-page">

                <section style={{ display: "flex", alignItems: "center", gap: "100px" }}>
                    <div className="about-intro">
                        <b>#1 on Community Impact</b>
                        <h1>Transforming Transport for All</h1>
                        <h2>About Diaspora Power Inc AGPUF</h2>
                        <p>
                            Diaspora Power Inc AGPUF is a non-profit organization committed to empowering communities through accessible connections.
                            With a primary focus on working with diaspora communities globally, our goal is to eradicate hunger and significantly
                            reduce poverty in countries such as Haiti and beyond. Through innovative community transportation services,
                            we strive to make a positive impact by providing affordable and reliable transportation solutions for everyone.
                        </p>



                        <h2>Experience and Expertise in Community Engagement</h2>
                        <p>
                            At Diaspora Power Inc AGPUF, our experience and expertise lie in our deep-rooted commitment to community engagement.
                            Through partnerships with local organizations and grassroots initiatives, we understand the unique needs of each
                            community we serve. By actively involving community members in the planning and implementation of our transportation
                            services, we create sustainable solutions that address real challenges and improve overall quality of life.
                        </p>



                        <h2>Authority in Poverty Alleviation and Hunger Relief</h2>
                        <p>
                            As a leading non-profit organization in the fight against poverty and hunger, we have built a reputation for excellence
                            in delivering impactful programs and initiatives. With a dedicated team of professionals and volunteers,
                            Diaspora Power Inc AGPUF leverages resources and networks to make a difference. Our proven track record of success
                            establishes us as a trusted authority in poverty alleviation and hunger relief.
                        </p>
                        <button className="sign-up-button">Sign up today</button>
                    </div>
                    <img style={{ width: "500px", borderRadius: "10px" }} src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1728318540318x608466571929011700%2FAI-Generated-Image.png?w=1024&h=1024&auto=compress&dpr=2&=&fit=crop&crop=entropy" alt="" />
                </section>



                <section style={{ display: "flex", alignItems: "center", gap: "100px" }}>

                    <img style={{ width: "500px", borderRadius: "10px" }} src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1728318527408x331788729952052350%2FAI-Generated-Image.png?w=1185.6&h=832&auto=compress&dpr=2&=&fit=crop&crop=entropy" alt="" />
                    <div>
                        <h2>Empowering Our Community</h2>
                        <p>
                            <strong>Trust in Reliable and Affordable Transportation Services:</strong> We are committed to providing reliable
                            and affordable services that meet the needs of our customers. Our fleet of vehicles is well-maintained and equipped
                            with the latest technology to ensure a safe and comfortable journey for all passengers. With transparent pricing and
                            flexible scheduling options, we make transportation accessible to everyone.
                        </p>

                        <p>
                            <strong>About Us: Making a Difference, One Ride at a Time:</strong> At Diaspora Power Inc AGPUF,
                            we believe that every ride counts. Whether it's a trip to the grocery store, a doctor's appointment,
                            or a job interview, we understand the importance of reliable transportation in connecting communities
                            and improving quality of life. Our dedicated team works tirelessly to ensure that each customer receives the highest level of service,
                            with a focus on safety, efficiency, and affordability. With Diaspora Power Inc AGPUF,
                            you can rest assured that you will arrive at your destination on time and with a smile.
                        </p>
                    </div>

                </section>

                <section className="community-services">
                    <h2>Discover Our Community Services</h2>
                    <div className="services-grid">
                        <div className="service-card"><h3>Community Focused</h3><p>Driven by our Community Focused mission.</p></div>
                        <div className="service-card"><h3>Eco-Friendly Fleet</h3><p>Choose green with our sustainable vehicle fleet.</p></div>
                        <div className="service-card"><h3>Volunteer Network</h3><p>Connect with dedicated volunteers easily.</p></div>
                        <div className="service-card"><h3>Flexible Routes</h3><p>Adapt routes for better accessibility and ease.</p></div>
                        <div className="service-card"><h3>Event Alerts</h3><p>Stay updated with punctual event notifications.</p></div>
                        <div className="service-card"><h3>Safe Routes</h3><p>Safe Routes ensure secure travel for all.</p></div>
                        <div className="service-card"><h3>Local Guides</h3><p>Local Guides help navigate community resources.</p></div>
                        <div className="service-card"><h3>Join and Share</h3><p>Join and Share rides to enhance community ties.</p></div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default About;
