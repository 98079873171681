import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { onValue, ref, off } from "firebase/database";
import { database } from "../firebaseConfig";
import Map, { Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Mapbox styles
import "./DriverPage.css";
import { Link, useLocation } from "react-router-dom";
import Notification from "../components/Notification";
// import { beAvailable, pickingUpOffer, getBalance, acceptRequest, updateDriverLocation } from "../request";
import { setupAccount, getPaymentsList, acceptOffer, driverWaiting, startTrip, finishRide, getBalance, closeOffer } from "../request";
const apiToken = process.env.REACT_APP_MAPBOX_TOKEN;



export default function DriverDashboard() {
    const { user, logout } = useAuth();
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState("Driver/Dashboard")
    const [driverDetails, setDriverDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userLocation, setUserLocation] = useState(null); // Store user's coordinates
    const [isOnline, setIsOnline] = useState(false)
    const [isLive, setIsLive] = useState(false)
    const [offer, setOffer] = useState(null)
    const notificationAudioRef = useRef(null)
    const [isBusy, setIsBusy] = useState(false)
    const [route, setRoute] = useState(null);

    const [paymentHistory, setPaymentHistory] = useState([])
    const [tripsHistory, setTripsHistory] = useState([])

    const [earnings, setEarnings] = useState(0)
    const [driverEarnings, setDriverEarnings] = useState(0)


    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        // console.log(location.pathname);
        setCurrentPage(location.pathname)
    }, [location])

    useEffect(() => {

        const resize = () => {
            setWidth(window.innerWidth);
        };

        // Add event listener
        window.addEventListener('resize', resize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    const [viewport, setViewport] = useState({
        latitude: 37.7749, // Default to San Francisco
        longitude: -122.4194,
        zoom: 12,
    });

    useEffect(() => {
        if (!user) return

        async function paymentsList() {
            const h = await getPaymentsList(user.driver.account.stripeAccountId)
            setPaymentHistory(h.transfers)
            // console.log(h);
        }

        paymentsList()
    }, [user, currentPage])

    useEffect(() => {
        if (!user) return

        async function tripList() {
            const h = user.driver.trips
            if (!h) return
            setTripsHistory(Object.values(h || {}))
            // console.log(h);
        }

        tripList()
    }, [user, currentPage])

    const [timer, setTimer] = useState(null)

    const triggerNotification = (text) => {
        clearTimeout(timer)
        setMessage(text);
        const t = setTimeout(() => {
            setMessage(""); // Reset the message after it disappears
            clearTimeout(timer)
        }, 10000); // Notification disappears after 3 seconds

        setTimer(t)
    };

    // fetchCar('Toyota', 'Corolla', 2017);


    useEffect(() => {
        if (!user) {
            navigate("/login"); // Redirect if not logged in
            return;
        }

        const fetchDriverDetails = () => {
            const userRef = ref(database, `users/${user.driver.uid}/driver`);

            onValue(
                userRef,
                (snapshot) => {
                    if (snapshot.exists()) {
                        setDriverDetails(snapshot.val());
                        // console.log("updated");
                        setLoading(false)
                    } else {
                        console.error("No driver data found!");
                    }
                },
                (error) => {
                    console.error("Error fetching driver data:", error);
                }
            );

            // Cleanup: Detach the listener when the component unmounts
            return () => {
                off(userRef);
            };
        };

        fetchDriverDetails();
    }, [user, navigate]);

    useEffect(() => {
        if (!notificationAudioRef.current) return;

        const audio = notificationAudioRef.current;

        if (offer && offer.status === "pending") {
            // Set up the audio source and enable looping
            audio.src = "/request_incoming.mp3";
            audio.loop = true;
            audio.play().catch((error) => {
                console.error("Failed to play audio:", error);
            });
            // Uncomment if you want to set a busy state when an offer is active
            // setIsBusy(true);
        } else {
            // Pause and reset audio when the offer is accepted or undefined
            audio.pause();
            audio.currentTime = 0; // Reset the playback position

            // Handle state updates when the offer is accepted
            if (offer?.status === "accepted") {
                setIsBusy(true);
            } else {
                setIsBusy(false)
            }
        }

        // Cleanup function to pause audio when the component unmounts
        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
        };
    }, [offer]);



    useEffect(() => {
        if (!user || !isLive) return;

        const offerRef = ref(database, "/offers");

        // Add a listener to the Firebase "offers" node
        const unsubscribe = onValue(offerRef, (snapshot) => {
            const offers = snapshot.val();
            let latestOffer = null;

            for (const offerId in offers) {
                const currentOffer = offers[offerId];

                // Check conditions:
                const isPending = currentOffer.status === "pending";
                const isDriverInvolved =
                    currentOffer.driverId === user.driver.uid &&
                    currentOffer.status !== "cancelled" &&
                    // currentOffer.status !== "completed" &&
                    currentOffer.status !== "closed";

                if (isPending || isDriverInvolved) {
                    latestOffer = { ...currentOffer, offerId };
                    break; // Stop looking as soon as a match is found
                }
            }

            // Update the state with the latest relevant offer
            setOffer(latestOffer || null); // null if no relevant offer is found
        });

        // Cleanup function
        return () => unsubscribe();
    }, [user, isLive]);

    useEffect(() => {
        if (!offer) return
        console.log(offer);
        if (offer && (offer.status === "closed" || offer.status === "cancelled")) {
            setOffer(null)
        } else if (offer.status === "abandon") {
            setEarnings((prev) => prev + offer.driverEarnings)
            setDriverEarnings(offer.driverEarnings)
        }
    }, [offer])


    const [cashBalance, setCashBalance] = useState(0)

    async function refreshCashBalance() {
        if (!user.driver.account) return
        const balance = await getBalance(user.driver.account.stripeAccountId, user.driver.uid)
        if (balance && balance.available) {
            setCashBalance(balance)
        }
    }

    useEffect(() => {
        if (!user) return
        refreshCashBalance()
    }, [user])


    useEffect(() => {
        // Fetch user's location on load
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([longitude, latitude]);
                    setViewport((prev) => ({
                        ...prev,
                        latitude,
                        longitude,
                    }));
                },
                (error) => {
                    console.error("Error fetching user location:", error);
                }
            );
        } else {
            console.error("Geolocation is not available");
        }
    }, []);

    const mapRef = useRef();
    useEffect(() => {
        const handleResize = () => {
            // Call map's resize method
            if (mapRef.current) {
                mapRef.current.resize();
            }
        };

        return () => handleResize(); // Cleanup
    }, [isOnline]);

    const [showDetails, setShowDetails] = useState(false)

    useEffect(() => {
        const unsub = setTimeout(() => {
            setShowDetails(isLive)
            clearTimeout(unsub)
        }, 400)
    }, [isLive])

    const toggleLive = async () => {
        if (mapRef.current && userLocation) {
            if (isLive) {
                stopWatchingLocation();
                const map = mapRef.current.getMap();
                map.flyTo({
                    center: userLocation,
                    zoom: 12,
                    bearing: 0,
                    pitch: 0,
                    duration: 2000, // Smooth transition in milliseconds
                });
                setIsLive(false)
                setOffer(null)
                triggerNotification("You are now on a break, click Go Offline if you wish to stop")
            } else {
                const map = mapRef.current.getMap();
                map.flyTo({
                    center: userLocation,
                    zoom: 18,
                    bearing: 180,
                    pitch: 60,
                    duration: 2000, // Smooth transition in milliseconds
                });
                setIsLive(true)
                triggerNotification("You are now available for riders to find you.")
            }
        }
    };

    const [locationWatcher, setLocationWatcher] = useState(null); // Store watcher ID

    const handleAcceptRequest = async () => {
        // Prepare initial data
        const data = {
            offerId: offer.offerId,
            driverId: user.driver.uid,
        };
        console.log("accepting: ", data);


        // Notify backend about the request acceptance
        await acceptOffer(data);
        // Start watching the location continuously
        // if ("geolocation" in navigator) {
        //     const watcherId = navigator.geolocation.watchPosition(
        //         (position) => {
        //             const { latitude, longitude } = position.coords;

        //             // Send the updated location to your backend
        //             const updatedData = {
        //                 offer: offer,
        //                 location: [longitude, latitude],
        //                 uid: user.userId,
        //                 firstName: user.firstName,
        //                 lastName: user.lastName,
        //                 profileImage: user.profileImage,
        //                 carModel: user.carModel,
        //                 carColor: user.carColor,
        //                 carImage: user.carImage,
        //                 tagNumber: user.tagNumber
        //             };

        //             const fetchRoute = async () => {
        //                 const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${longitude},${latitude};${offer.pickupLocation[0]},${offer.pickupLocation[1]}?geometries=geojson&access_token=${apiToken}`;
        //                 const response = await fetch(url);
        //                 const data = await response.json();

        //                 if (data.routes && data.routes.length > 0) {
        //                     setRoute(data.routes[0].geometry);
        //                     const map = mapRef.current; // Assume mapRef is your map reference

        //                     if (map) {
        //                         const points = [
        //                             [longitude, latitude], // Point 1
        //                             [offer.pickupLocation[0], offer.pickupLocation[1]],   // Point 2
        //                         ];

        //                         map.fitBounds(points, {
        //                             padding: 50,   // Padding around the edges
        //                             maxZoom: 17,   // Restrict maximum zoom
        //                             linear: true,  // Disable animation
        //                         });
        //                     }
        //                 }
        //             };

        //             fetchRoute()

        //             // Update backend with new location
        //             // updateDriverLocation(updatedData); // Replace this with your backend call
        //         },
        //         (error) => {
        //             console.error("Error watching position:", error);
        //         },
        //         { enableHighAccuracy: true, maximumAge: 10000 } // Options for accuracy
        //     );

        //     // Store watcher ID for later cleanup
        //     setLocationWatcher(watcherId);
        // } else {
        //     console.error("Geolocation is not available");
        // }
    };

    // Cleanup watcher when no longer needed
    const stopWatchingLocation = () => {
        if (locationWatcher !== null) {
            navigator.geolocation.clearWatch(locationWatcher);
            setLocationWatcher(null); // Reset watcher ID
        }
    };

    useEffect(() => {
        if (!offer || !offer.offerOption) {
            if (locationWatcher !== null) {
                navigator.geolocation.clearWatch(locationWatcher);
                setLocationWatcher(null); // Reset watcher ID
                setRoute(null)
            }
        }
    }, [offer, locationWatcher])


    const [waitingTime, setWaitingTime] = useState("5:00");
    const [waitingTimeInterval, setWaitingTimeInterval] = useState(null);

    useEffect(() => {
        let time = 1 * 60; // Total time in seconds (5 minutes)
        if (offer && offer.status === "waiting") {
            const interval = setInterval(() => {
                time--;
                const min = Math.floor(time / 60);
                const sec = time % 60;
                setWaitingTime(`${String(min).padStart(2, "0")}:${String(sec).padStart(2, "0")}`);

                if (time <= 0) {
                    clearInterval(interval); // Clear interval when time reaches 0
                    setWaitingTime("00:00");
                }
            }, 1000);

            setWaitingTimeInterval(interval);
        }

        return () => {
            // Cleanup interval on component unmount or offer change
            if (waitingTimeInterval) {
                clearInterval(waitingTimeInterval);
            }
        };
    }, [offer]); // Dependency array only includes `offer`


    const [showNavBar, setShowNavBar] = useState(false)


    useEffect(() => {
        setShowNavBar(false)
    }, [currentPage])



    const handleArrival = async () => {
        if (!user) return

        await driverWaiting({ driverId: user.driver.uid, offerId: offer.offerId })
    }

    const handleStartTrip = async () => {
        if (!user) return

        await startTrip({ driverId: user.driver.uid, offerId: offer.offerId })
    }

    const handleFinishRide = async () => {
        if (!user) return

        const result = await finishRide({ driverId: user.driver.uid, offerId: offer.offerId })

        if (result.driverEarnings) {
            setEarnings((prev) => prev + result.driverEarnings)
            setDriverEarnings(result.driverEarnings)
        }
    }

    const handleClose = async () => {
        if (!user) return

        await closeOffer({ driverId: user.driver.uid, offerId: offer.offerId })
    }


    if (loading)
        return (
            <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span className="loading-indicator" />
            </div>
        );

    if (!driverDetails)
        return (
            <div className="error">
                <h2>No driver details found!</h2>
                <p>Please contact support or update your profile.</p>
            </div>
        );

    return (
        <div style={{ display: "flex" }}>

            {!isOnline && <div className={`nav-bar-container ${showNavBar ? "open" : ""}`}>
                <div className="nav-bar close">
                    {/* Profile Section */}
                    <div className="nav-section">
                        <div className="nav-profile">
                            <img
                                src={driverDetails.profilePicture || "/pf.jpg"}
                                style={{ aspectRatio: 1, borderRadius: "100%" }}
                                alt="profile_image"
                            />
                            <div className="info" style={{ display: "flex", alignItems: "center" }}>
                                <p>
                                    {driverDetails.firstName} {driverDetails.lastName}
                                </p>
                            </div>
                        </div>
                        <ul className="nav-links">
                            <li>
                                <Link to="/driver/edit-profile">
                                    <ion-icon name="pencil-outline" />
                                    <span>Edit Profile</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/change-password">
                                    <ion-icon name="lock-closed-outline" />
                                    <span>Change Password</span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="nav-section">
                        <hr />
                        <ul className="nav-links">
                            <li>
                                <Link to="/driver/dashboard">
                                    <ion-icon name="grid-outline"></ion-icon>
                                    <span>Dashboard</span>
                                </Link>
                            </li>

                            {/* <li>
                                <Link to="/driver/rewards">
                                    <ion-icon name="gift-outline"></ion-icon>
                                    <span>Rewards</span>
                                </Link>
                            </li> */}
                        </ul>

                    </div>
                    {driverDetails.status === "verified" &&
                        <>


                            < div className="nav-section">
                                <hr />
                                <ul className="nav-links">
                                    <li>
                                        <Link to="/driver/payment-history">
                                            <ion-icon name="wallet-outline" />
                                            <span>Payment History</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            {/* Statements Section */}
                            <div className="nav-section">
                                <hr />
                                <ul className="nav-links">
                                    <li>
                                        <Link to="/driver/trip-statements">
                                            <ion-icon name="document-text-outline" />
                                            <span>Trip Statements</span>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/driver/earnings-reports">
                                            <ion-icon name="analytics-outline" />
                                            <span>Earnings Reports</span>
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/driver/tax-documents">
                                            <ion-icon name="file-tray-outline" />
                                            <span>Tax Documents</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </>
                    }

                    {/* Other Actions */}
                    <div className="nav-section">
                        <hr />
                        <ul className="nav-links">
                            {/* <li>
                                <Link to="/driver/settings">
                                    <ion-icon name="settings-outline" />
                                    <span>Settings</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/driver/help">
                                    <ion-icon name="help-circle-outline" />
                                    <span>Help & Support</span>
                                </Link>
                            </li>
                            <li>
                                <button onClick={logout} className="logout-button stop">
                                    <ion-icon name="log-out-outline" />
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>}





            <div style={{ flex: 1 }}>

                <div className="dashboard-header">
                    {width > 678 && <div> {currentPage}</div>}

                    {width > 678 && <label htmlFor="search-input" className="searchbox">
                        <ion-icon name="search"></ion-icon>
                        <input id="search-input" onFocus={(e) => { e.target.labels[0].classList.add("focus") }} onBlur={(e) => { e.target.labels[0].classList.remove("focus") }} type="text" placeholder="Search Dashboard" />
                    </label>}

                    {!isOnline && width < 678 && <span onClick={() => {
                        setShowNavBar(!showNavBar)
                    }}>
                        <ion-icon name={showNavBar ? "close" : "menu"} style={{ fontSize: "24px" }}></ion-icon>
                    </span>}

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button disabled={isLive} className={isOnline ? "stop" : "start"} onClick={() => setIsOnline(!isOnline)}>{isOnline ? "Go Offline" : "Go Online"}</button>
                        {!isOnline && <button onClick={() => navigate("/")}>Home</button>}

                    </div>
                </div>
                {currentPage === "/driver/dashboard" &&
                    <div className="driver-dashboard">


                        {!isOnline && <div style={{ display: "flex", gap: "10px", flexDirection: width > 678 ? "row" : "column" }}>
                            <section className="dashboard-section">
                                <b>
                                    <ion-icon name="person-outline"></ion-icon>
                                    Profile
                                </b>
                                <hr />
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "20px" }} onClick={() => navigate('/driver/edit-profile')}>
                                    <img src={driverDetails.profilePicture || "/pf.jpg"} style={{ aspectRatio: 1, borderRadius: "100%" }} alt="p" />


                                    <div>
                                        <p>
                                            <span style={{ fontSize: "10px" }}>FULL NAME</span>
                                            <br />
                                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                                                {driverDetails.firstName}{" "}
                                                {driverDetails.lastName.slice(0, 1)}.
                                            </span>
                                        </p>

                                        <p>
                                            <span style={{ fontSize: "10px" }}>Status</span>
                                            <br />
                                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                                                {driverDetails.status || "unverified"}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </section>

                            {driverDetails.status === "verified" &&

                                <section className="dashboard-section">
                                    <b>
                                        <ion-icon name="car-outline"></ion-icon>
                                        My Vehicle
                                    </b>
                                    <hr />
                                    {driverDetails.car ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "20px" }}>
                                            <img src={driverDetails.car.image} alt="vehicle_image" />
                                            <div>
                                                <Item name="MODEL" value={driverDetails.car.model || "N/A"} />
                                                <Item name="COLOR" value={driverDetails.car.color || "N/A"} />
                                                <Item name="LICENSE PLATE" value={driverDetails.car.tagNumber || "N/A"} />
                                            </div>
                                        </div> :
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "20px" }}>
                                            <button>Setup My Vehicle</button>
                                        </div>}

                                </section>
                            }

                        </div>}


                        {driverDetails.status === "verified" &&
                            <div style={{ display: "flex", gap: "10px", flexDirection: width > 678 ? "row" : "column" }}>
                                {!isOnline && <>
                                    <section className="dashboard-section" style={{ flex: 2, display: "flex", flexDirection: "column" }}>
                                        <b>
                                            <ion-icon name="wallet-outline"></ion-icon>
                                            My Account
                                        </b>
                                        {driverDetails.account.routingNumber && driverDetails.account.accountNumber ?
                                            <>
                                                <h2>{"$" + (((cashBalance?.available?.usd || 0) * 0.01).toFixed(2))}</h2>
                                                <div style={{ fontSize: "12px" }}>
                                                    <p style={{ letterSpacing: "2px" }}>

                                                        Account********{driverDetails.account.accountNumber.slice(-4)}
                                                    </p>
                                                    <p style={{ letterSpacing: "2px" }}>
                                                        Routing*********{driverDetails.account.routingNumber.slice(-3)}
                                                    </p>
                                                </div>

                                                <div style={{ margin: "-10px", marginTop: "auto", display: "flex" }}>
                                                    <button>Add</button>
                                                    <button>Withdraw</button>
                                                </div>
                                            </> : <button>Setup My Account</button>
                                        }

                                    </section>


                                    <div style={{ display: "flex", flexDirection: width > 678 ? "column" : "row" }}>

                                        <section className="dashboard-section">
                                            <b>
                                                <ion-icon name="cash-outline"></ion-icon>
                                                Total Earnings
                                            </b>
                                            <hr />
                                            <h3>
                                                ${driverDetails.account.total_earnings ? (Math.round(driverDetails.account.total_earnings * 10) / 1000).toFixed(2) : "0.00"}
                                            </h3>
                                        </section>
                                        <section className="dashboard-section">
                                            <b>
                                                <ion-icon name="time-outline"></ion-icon>
                                                Pending
                                            </b>
                                            <hr />
                                            <h3>
                                                {"$" + (((cashBalance?.pending ? cashBalance.pending[0].amount : 0) * 0.01).toFixed(2))}
                                            </h3>
                                        </section>


                                    </div>
                                </>}


                                <section className="dashboard-section" onClick={() => setIsOnline(true)} style={{ flex: 3, position: "relative", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px", height: isOnline ? "calc(100vh - 210px)" : "unset", minHeight: width > 678 ? "unset" : "100px" }}>
                                    <Map
                                        ref={mapRef}
                                        mapboxAccessToken={apiToken}
                                        {...viewport} // Spread the updated viewport state
                                        onMove={(evt) => setViewport(evt.viewState)}
                                        style={{ width: "100%", height: "100%", minWidth: "100%", minHeight: "100%", borderRadius: "10px" }}
                                        mapStyle="mapbox://styles/mapbox/streets-v11"
                                    >
                                        {/* Marker for User's Location */}

                                        {route && (
                                            <Source id="route" type="geojson" data={{ type: "Feature", geometry: route }}>
                                                <Layer
                                                    id="route-line"
                                                    type="line"
                                                    layout={{ "line-cap": "round", "line-join": "round" }}
                                                    paint={{
                                                        "line-color": "#000000",
                                                        "line-width": 5,
                                                    }}
                                                />
                                            </Source>
                                        )}

                                        {userLocation && (
                                            <Marker
                                                longitude={userLocation[0]}
                                                latitude={userLocation[1]}
                                                anchor="bottom"
                                            >
                                                <img
                                                    src="https://docs.mapbox.com/help/demos/custom-markers-gl-js/mapbox-icon.png"
                                                    alt="User Location"
                                                    style={{ height: "30px", width: "30px" }}
                                                />
                                            </Marker>
                                        )}
                                    </Map>


                                    {isOnline &&
                                        <div
                                            className="drive-details-overlay"
                                            style={{
                                                "--width": `${((Math.round(earnings * 1000) / 1000).toFixed(2)).length * 8 + 74}px`,
                                                animation: isLive ? "open 0.3s ease-in-out" : "close 0.3s ease-in-out",
                                                animationFillMode: isLive ? "forwards" : "backwards",
                                                height: "35px"
                                            }}
                                        >
                                            {isLive && showDetails && (
                                                <div style={{ display: "flex", alignItems: "center", gap: "20px", marginLeft: "10px", color: "white", fontWeight: "bold" }}>

                                                    <span>${(Math.round(earnings * 1000) / 1000).toFixed(2)}</span>

                                                </div>
                                            )}
                                            {!isBusy &&
                                                <button
                                                    style={{ marginLeft: "auto", padding: isLive ? "0px 10px" : "0px", transition: "0.3s" }}
                                                    className={isLive ? "stop" : "start"}
                                                    onClick={toggleLive}
                                                >
                                                    {isLive ? "End" : "Go"}
                                                </button>
                                            }



                                        </div>}
                                    {offer && offer.offerOption && offer.status === "pending" && <div className="drive-offer-overlay">
                                        <span className="offer-header">New Offer</span>
                                        <h3>${offer.offerOption.price}</h3>

                                        <div>
                                            <div className="reques-addresses"><span className="af"><div /></span>{offer.pickup}</div>
                                            <div className="reques-addresses"><span className="ad"></span>{offer.dropoff}</div>
                                        </div>

                                        <button className="accept-request-button" onClick={handleAcceptRequest}>Accept</button>

                                    </div>}

                                    {offer && offer.driverId === user.driver.uid && <div className="rider-details-overlay">
                                        {offer && offer.status === "accepted" && <h4 style={{ margin: "0px", display: "flex", justifyContent: "space-between" }}>Offer from {offer.rider.firstName}</h4>}

                                        {offer && offer.status === "waiting" && <h4 style={{ margin: "0px", display: "flex", justifyContent: "space-between" }}>Waiting for {offer.rider.firstName} <span style={{ textAlign: "right" }}>{waitingTime}</span></h4>}

                                        {offer && offer.status === "abandon" && <h4 style={{ margin: "0px", display: "flex", justifyContent: "space-between" }}>{offer.rider.firstName} didn't show</h4>}
                                        {offer && offer.status === "in_progress" && <h4 style={{ margin: "0px", display: "flex", justifyContent: "space-between" }}>{"&<DIRECTIONS>"}</h4>}
                                        {offer && offer.status === "completed" && <h4 style={{ margin: "0px", display: "flex", justifyContent: "space-between" }}>Finished</h4>}
                                        <hr style={{ borderColor: "#0001", width: "100%" }} />
                                        {offer && (offer.status !== "abandon" || offer.status !== "completed") && <div>
                                            <div className="reques-addresses"><span className="af"><div /></span>{offer.pickup}</div>
                                            <div className="reques-addresses"><span className="ad"></span>{offer.dropoff}</div>
                                        </div>}

                                        {offer && offer.status === "abandon" && <div>
                                            <div className="reques-addresses">You've earn<strong>${(Math.round(driverEarnings * 100) / 100).toFixed(2)}</strong>for your time</div>
                                        </div>}

                                        {offer && offer.status === "completed" && <div>
                                            <div className="reques-addresses">You've earn<strong>${(Math.round(driverEarnings * 100) / 100).toFixed(2)}</strong>from this ride</div>
                                        </div>}

                                        {offer && offer.status === "accepted" && <button className="accept-request-button" onClick={handleArrival}>I'm here</button>}
                                        {offer && offer.status === "waiting" && <button className="accept-request-button" onClick={handleStartTrip}>Start Trip</button>}
                                        {offer && offer.status === "in_progress" && <button className="accept-request-button" onClick={handleFinishRide}>Finish</button>}
                                        {offer && !["in_progress", "abandon", "completed"].includes(offer.status) && <button onClick={handleArrival}>Cancel</button>}
                                        {offer && (offer.status === "abandon" || offer.status === "completed") && <button onClick={handleClose}>Close</button>}


                                    </div>}
                                </section>
                            </div>
                        }
                    </div>}

                {currentPage === "/driver/payment-history" &&
                    <div className="driver-dashboard">
                        <div className="paymentHistaoryTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Payment ID</th>
                                        <th>Date</th>
                                        <th>Ammout</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentHistory.map((payment, index) => (
                                        <tr key={index}>
                                            <td className="id">#{index + 1}</td>
                                            <td className="date">{new Date(payment.created * 1000).toLocaleString()}</td>
                                            <td className="amount">${(payment.amount / 100).toFixed(2)}</td>
                                            <td className="status">
                                                <span className={payment.status}>{payment.status}</span>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                }

                {currentPage === "/driver/trip-statements" &&
                    <div className="driver-dashboard">
                        <div className="paymentHistaoryTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Trip ID</th>
                                        <th>Rider Name</th>
                                        <th>Date</th>
                                        <th>Ammout</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tripsHistory
                                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by date, newest first
                                        .map((trip, index) => (
                                            <tr key={index}>
                                                <td className="id">#{index + 1}</td>
                                                <td className="id">{trip.rider}</td>
                                                <td className="date">{new Date(trip.createdAt).toLocaleString()}</td>
                                                <td className="amount">${(trip.amount / 100).toFixed(2)}</td>
                                                <td className="status">
                                                    <span className={trip.status}>{trip.status}</span>
                                                </td>
                                            </tr>
                                        ))}

                                </tbody>
                            </table>
                        </div>
                    </div>

                }

            </div>
            <audio ref={notificationAudioRef} />
            <Notification message={message} duration={3000} />
            {!driverDetails.account &&
                <SetupStripeAccount driverDetails={driverDetails} />
            }
        </div >

    );
}


function Item({ name, value }) {

    return (
        <p>
            <span style={{ fontSize: "10px" }}>{name}</span>
            <br />
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                {value}
            </span>
        </p>
    )
}





function SetupStripeAccount({ driverDetails, onClose = () => { } }) {
    const [error, setError] = useState("")
    const [formData, setFormData] = useState({
        uid: driverDetails.uid,
        documents: { front: driverDetails.licenseFront, back: driverDetails.licenseBack },
        accountNumber: "",
        routingNumber: "",
        ssn: "",
        email: driverDetails.email,
        firstName: driverDetails.firstName,
        lastName: driverDetails.lastName,
        dob: driverDetails.dob,
        termsAccepted: false, // Terms of Service checkbox
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        const missingFields = Object.entries(formData)
            .filter(([key, value]) => !value && key !== "tos") // Exclude tos from this filter
            .map(([key]) => key);

        if (missingFields.length > 0) {
            setError(`Please fill in the following fields: ${missingFields.join(", ")}`);
            return;
        }

        if (!formData.termsAccepted) {
            setError("You must accept the terms of service.");
            return;
        }

        // Send data to backend
        try {
            const account = await setupAccount(formData)
            if (account.error) {
                setError(account.error)
            } else {
                console.log(account);
                setError("")
                onClose()
            }

        } catch (err) {
            console.error("Error submitting form:", err);
            setError("An error occurred while setting up the account.");
        }
    };

    return (
        <div className="modal-container" onClick={onClose}>
            <div className="modal" style={{ width: "500px" }} onClick={(e) => e.stopPropagation()}>
                <div className="header">
                    <b>Setup Account</b>
                    <span onClick={onClose}>
                        <ion-icon name="close-outline"></ion-icon>
                    </span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div
                        style={{
                            overflow: "auto",
                            maxHeight: "60vh",
                            margin: "10px",
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                            gap: "20px",
                            padding: "20px",
                            perspective: "500px",
                        }}
                    >
                        <InputBox
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            name="firstName"
                        />
                        <InputBox
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            name="lastName"
                        />
                        <InputBox
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                            type="email"
                        />
                        <InputBox
                            placeholder="SSN"
                            value={formData.ssn}
                            onChange={handleChange}
                            name="ssn"
                            type="text"
                        />
                        <InputBox
                            placeholder="Date of Birth (YYYY-MM-DD)"
                            value={formData.dob}
                            onChange={handleChange}
                            name="dob"
                            type="date"
                        />
                        <InputBox
                            placeholder="Routing Number"
                            value={formData.routingNumber}
                            onChange={handleChange}
                            name="routingNumber"
                            type="text"
                        />
                        <InputBox
                            placeholder="Account Number"
                            value={formData.accountNumber}
                            onChange={handleChange}
                            name="accountNumber"
                            type="text"
                        />
                    </div>
                    <div style={{ padding: "0 20px" }}>
                        <label>
                            <input
                                type="checkbox"
                                name="termsAccepted"
                                checked={formData.termsAccepted}
                                onChange={handleChange}
                            />
                            I accept the Terms of Service
                        </label>
                    </div>
                    <p style={{ color: "red", fontSize: "12px", textAlign: "center" }}>{error}</p>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <button type="submit" className="submit-button">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function InputBox({ placeholder, value, onChange, name, type = "text" }) {
    return (
        <div className="input-box" style={{
            width: "150px"
        }}>
            <input

                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
            />
        </div>
    );
}

