import React from "react";
import "./Footer.css"; // Import the footer styles

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* About Section */}
                <div className="footer-section" style={{ flex: 5 }}>
                    <h4>Diaspora Power Inc AGPUF</h4>

                    <img style={{ height: "50px", borderRadius: "10px" }} alt="icon" src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1732588874637x697593211426873200%2F_DPI%2520Logo%25201%2520%25281%2529.png?w=96&h=96&auto=compress&dpr=2&fit=max" />
                    <p>
                        We aim to make transportation easy and sustainable for everyone in the community. Join us in reshaping mobility for a better future.
                    </p>
                </div>

                {/* Links Section */}
                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#ride">Ride</a></li>
                        <li><a href="#services">Services</a></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h4>Support</h4>
                    <ul>
                        <li><a href="/faqs">FAQs</a></li>
                        <li><a href="/contactUs">Contact us</a></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h4>Terms & Privacy</h4>
                    <ul>
                        <li><a href="/terms">Terms</a></li>
                        <li><a href="/privacy">Privacy</a></li>
                    </ul>
                </div>

                {/* Social Media Section */}
                <div className="footer-section">
                    <h4>Follow Us</h4>
                    <div className="social-icons">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                            <ion-icon name="logo-facebook"></ion-icon>
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <ion-icon name="logo-twitter"></ion-icon>
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                            <ion-icon name="logo-instagram"></ion-icon>
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <ion-icon name="logo-linkedin"></ion-icon>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Diaspora Power Inc AGPUF. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
