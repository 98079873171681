import React, { useState, useEffect, useRef, useCallback } from "react";
import Map, { Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Mapbox styles
import { onValue, ref, off } from "firebase/database";
import { useAuth } from "../AuthContext";
import { getOffers, findDriver, cancelSearching, createCustomer } from "../request";
import { database } from "../firebaseConfig";
import { useNavigate, useLocation, useParams } from "react-router";


import AddCard from "../components/AddCard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";



const stripeKey = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripeKey);



const apiToken = process.env.REACT_APP_MAPBOX_TOKEN;


export default function Rider() {
    const location = useLocation()
    const params = useParams()

    const { user, setUser } = useAuth()
    const [userLocation, setUserLocation] = useState(null); // Store user's coordinates
    const [pickup, setPickup] = useState("");
    const [dropoff, setDropoff] = useState("");
    const [pickupLocation, setPickupLocation] = useState(null);
    const [dropoffLocation, setDropoffLocation] = useState(null);
    const [pickupSuggestions, setPickupSuggestions] = useState([]);
    const [dropoffSuggestions, setDropoffSuggestions] = useState([]);
    const [offerOptions, setOfferOptions] = useState([]);
    const [offerOption, setOfferOption] = useState(null);
    const [tip, setTip] = useState(false)
    const [abandonFee, setAbandonFee] = useState(0)
    const [offerState, setOfferState] = useState("none")
    const [offer, setOffer] = useState(null)
    const [offerId, setOfferId] = useState(null)
    const navigate = useNavigate()
    const [addingCard, setAddingCard] = useState(false)

    useEffect(() => {
        if (user && !user.rider) {
            (async () => {
                const { customer } = await createCustomer(user.driver.uid, {
                    email: user.driver.email,
                    firstName: user.driver.firstName,
                    lastName: user.driver.lastName
                })
                setUser(customer)
                setAddingCard(true)
                console.log(customer);
            })()

        }
    }, [user])

    const mapRef = useRef(null)
    const [viewport, setViewport] = useState({
        latitude: 37.7749, // Default to San Francisco
        longitude: -122.4194,
        zoom: 12,
    });

    const [route, setRoute] = useState(null);
    const [driverRoute, setDriverRoute] = useState(null);

    // Fetch route from Mapbox Directions API
    useEffect(() => {
        if (!user) return
        const fetchRoute = async () => {
            const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${pickupLocation[0]},${pickupLocation[1]};${dropoffLocation[0]},${dropoffLocation[1]}?geometries=geojson&access_token=${apiToken}`;
            const response = await fetch(url);
            const data = await response.json();

            if (data.routes && data.routes.length > 0) {
                setRoute(data.routes[0].geometry);
                const offers = await getOffers(user.userId, user.customerId, data.routes[0].distance, data.routes[0].duration)
                setOfferOptions(offers.offers)
            }
        };

        if (pickupLocation && dropoffLocation) {
            fetchRoute();
            setOfferOption(null)
        }
    }, [pickupLocation, dropoffLocation, user]);

    useEffect(() => {
        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([longitude, latitude]);
                    setViewport((prev) => ({
                        ...prev,
                        latitude,
                        longitude,
                    }));
                },
                (error) => {
                    console.error("Error fetching user location:", error);
                }
            );
        } else {
            console.error("Geolocation is not available");
        }
    }, [user]);

    const fetchSuggestions = (query, setSuggestions) => {
        if (!query || !userLocation) {
            setSuggestions([]);
            return;
        }

        const [longitude, latitude] = userLocation;
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${apiToken}&autocomplete=true&proximity=${longitude},${latitude}&types=region,place,locality,address`
            ;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.features) {
                    setSuggestions(data.features.map((feature) => feature));
                }
            })
            .catch((error) => console.error("Error fetching suggestions:", error));
    };


    useEffect(() => {
        // console.log(!userLocation);
        if (!userLocation || pickup.length !== 0) return
        const [longitude, latitude] = userLocation;
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${apiToken}&autocomplete=true&proximity=${longitude},${latitude}&types=region,place,locality,address`

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.features) {
                    // setCurrentLocation(data.features.map((feature) => feature)[0]);
                    const locationName = data.features[0].place_name
                    setPickupLocation(userLocation)
                    setPickup(`${locationName}`)
                    if (mapRef.current) {
                        // Optionally, if the destination changes, update the map view
                        mapRef.current.jumpTo({
                            center: userLocation,
                            essential: true,
                        });
                    }
                }
            })
            .catch((error) => console.error("Error fetching suggestions:", error));
    }, [userLocation, pickup])

    const [insufficient, setInsufficient] = useState(false)

    const searchDriver = useCallback(async (e) => {
        e.stopPropagation()
        const data = {
            riderId: user.rider.uid,
            pickupLocation,
            dropoffLocation,
            pickup,
            dropoff,
            offerOption
        }

        const result = await findDriver(data);

        if (result.data && result.data.offerId) {
            setOfferId(result.data.offerId);
        } else {
            setInsufficient(true)
        }

        console.log(result);

    }, [dropoffLocation, offerOption, pickup, pickupLocation, user, dropoff])

    useEffect(() => {
        if (!user || !offerId) return setOffer(null);

        const offerRef = ref(database, `/offers/${offerId}/`);

        // Add a listener to the Firebase "offers" node
        onValue(offerRef, (snapshot) => {
            const offer = snapshot.val();
            const status = offer.status

            // Update the state with the latest relevant offer
            if (status === "completed" || status === "cancelled") {
                if (status === "completed") {
                    setTip(true)
                } else if (status === "abandon") {
                    setAbandonFee(offer.abandonFee)
                }
                setOffer(null)
                setOfferState("none")
                setOfferId("")
                setOfferOptions([])
                setOfferOption(null)
                setDropoffLocation(null)
                setDropoff("");
                setDropoffSuggestions([]);
                off(offerRef)
            } else {
                setOffer({ ...offer, offerId }); // null if no relevant offer is found
                setOfferState(status)
            }

        });

    }, [user, offerId]);

    const cancel = useCallback(async (e) => {
        console.log("Cancelling search...");

        e.target.disabled = true
        e.target.innerText = "cancelling..."

        try {
            const data = await cancelSearching(user.rider.uid, offer.offerId);
            e.target.disabled = false
            e.target.innerText = "cancel"


            console.log(data);
        } catch (err) {
            console.error("Error cancelling search:", err);
            e.target.disabled = false
            e.target.innerText = "cancel"


        }
    }, [offer, user]);


    const reset = () => {
        setOffer(null)
        setOfferState("none")
        setOfferId("")
        setOfferOptions([])
        setOfferOption(null)
        setDropoffLocation(null)
        setDropoff("");
        setDropoffSuggestions([]);
    }



    useEffect(() => {
        if (offer && offer.driver && offer.driver.location && offer.status === "accepted") {
            const fetchRoute = async () => {
                const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${pickupLocation[0]},${pickupLocation[1]};${offer.driver.location[0]},${offer.driver.location[1]}?geometries=geojson&access_token=${apiToken}`;
                const response = await fetch(url);
                const data = await response.json();

                if (data.routes && data.routes.length > 0) {
                    setDriverRoute(data.routes[0].geometry);
                }

                const map = mapRef.current; // Assume mapRef is your map reference

                if (map) {
                    const points = [
                        [pickupLocation[0], pickupLocation[1]], // Point 1
                        [offer.driver.location[0], offer.driver.location[1]],   // Point 2
                    ];

                    map.fitBounds(points, {
                        padding: width < 678 ? { top: 120, bottom: 400, left: 50, right: 50 } : 120,
                        maxZoom: width < 678 ? 12 : 18,
                        linear: true
                    });
                }

            };

            fetchRoute()
        } else {
            setDriverRoute(null)
        }
    }, [offer, pickupLocation])


    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {

        const resize = () => {
            setWidth(window.innerWidth);
        };

        // Add event listener
        window.addEventListener('resize', resize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);



    return (
        <>
            {user ?
                <>
                    <div className="header-background" style={{ margin: "0px" }}></div>
                    <div style={{ display: "flex", height: "100vh", marginTop: "-10em" }}>
                        {/* Left: Input Section */}
                        <div className="riderInputSection" style={{
                            flex: 1, display: "flex", alignItems: "center", justifyContent: "center",
                            "--hiddenPanel": `translateY(${offerState === "none"
                                ? "0px" : offerState === "pending"
                                    ? "220px" : offerState === "in_progress"
                                        ? "260px" : "0px"
                                })`
                        }}>
                            <div className="rider-input-container">
                                {!insufficient ? <>
                                    {!offerOptions && !route && <h2 style={{ textAlign: "center" }}>Search Driver</h2>}

                                    {/* Pickup Input */}
                                    {!offer && <><div className="input-box">
                                        <span className="pl-circle">
                                            <span className="connected-line"></span>
                                        </span>
                                        <input
                                            type="text"
                                            placeholder={"Pick up location"}
                                            value={pickup}
                                            // disabled={isSearching}
                                            onChange={(e) => {
                                                setPickup(e.target.value);
                                                fetchSuggestions(e.target.value, setPickupSuggestions);
                                            }}
                                        />
                                        {/* Pickup Suggestions */}
                                        {pickupSuggestions.length > 0 && (
                                            <ul className="suggestions-list">
                                                {pickupSuggestions.map((suggestion, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={() => {
                                                            if (mapRef.current) {
                                                                // Optionally, if the destination changes, update the map view
                                                                if (dropoffLocation) {
                                                                    mapRef.current.fitBounds(
                                                                        [dropoffLocation, suggestion.center],
                                                                        {
                                                                            padding: width < 678 ? { top: 120, bottom: 400, left: 50, right: 50 } : 120,
                                                                            maxZoom: width < 678 ? 12 : 18,
                                                                            linear: true
                                                                        }
                                                                    );
                                                                } else {
                                                                    mapRef.current.flyTo({
                                                                        center: suggestion.center,
                                                                        essential: true,
                                                                    });
                                                                }
                                                            }
                                                            setPickup(suggestion.place_name);
                                                            setPickupLocation(suggestion.center)
                                                            setPickupSuggestions([]);
                                                        }}
                                                    >
                                                        {suggestion.place_name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                        {/* Dropoff Input */}
                                        <div className="input-box">
                                            <span className="df-square"></span>
                                            <input
                                                type="text"
                                                placeholder="Drop off location"
                                                value={dropoff}
                                                onChange={(e) => {
                                                    setDropoff(e.target.value);
                                                    fetchSuggestions(e.target.value, setDropoffSuggestions);
                                                }}
                                            />
                                            {/* Dropoff Suggestions */}
                                            {dropoffSuggestions.length > 0 && (
                                                <ul className="suggestions-list">
                                                    {dropoffSuggestions.map((suggestion, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => {
                                                                if (mapRef.current) {
                                                                    // Optionally, if the destination changes, update the map view
                                                                    if (pickupLocation) {
                                                                        mapRef.current.fitBounds(
                                                                            [pickupLocation, suggestion.center],
                                                                            {
                                                                                padding: width < 678 ? { top: 120, bottom: 400, left: 50, right: 50 } : 120,
                                                                                maxZoom: width < 678 ? 12 : 18,
                                                                                linear: true
                                                                            }
                                                                        );
                                                                    } else {
                                                                        mapRef.current.flyTo({
                                                                            center: suggestion.center,
                                                                            essential: true,
                                                                        });
                                                                    }
                                                                }
                                                                setDropoffLocation(suggestion.center)
                                                                setDropoff(suggestion.place_name);
                                                                setDropoffSuggestions([]);
                                                            }}
                                                        >
                                                            {suggestion.place_name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div> </>}

                                    {offerState === "none" && (
                                        <>

                                            {offerOptions && route && (
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {offerOptions.map((offer, index) => (
                                                        <div key={index} className={`offerItems ${offerOption?.type === offer.type ? "selected" : ""}`} onClick={() => setOfferOption(offer)}>
                                                            <div>
                                                                <h4 style={{ margin: "5px 0px 0px 0px", padding: "0px" }}>{offer.type}</h4>
                                                                <div style={{ fontSize: "10px", margin: "5px 0px" }}>{offer.waitTime}mins wait time</div>
                                                            </div>
                                                            <p>${offer.price}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            <button disabled={!offerOption} className="search-button" onClick={searchDriver}>Search</button>
                                        </>
                                    )}


                                    {offerState === "pending" && (
                                        <>
                                            <div className="driver-info-rows" style={{ width: "calc(100% - 20px)" }}><b>Searching</b><b>Estimate: 5:00</b></div>

                                            <div className="searching">
                                                <span className="loading-indicator" />
                                                <b>We will get you a driver within {offer?.offerOption?.waitTime} minutes</b>
                                                {/* <p style={{ fontSize: "10px" }}>Note: If you cancel, you will get only 90% of your money back on you Cash Balance.</p> */}
                                            </div>
                                            <button disabled={!offer} className="search-button" onClick={cancel}>cancel</button>
                                        </>
                                    )}

                                    {(offerState === "accepted" || offerState === "waiting" || offerState === "in_progress") && (
                                        <>
                                            <div className="driver-info">
                                                {offerState === "accepted" && <div className="driver-info-rows"><b>Driver Found</b><b style={{ fontSize: "10px" }}>Arrive in 5 mins</b></div>}
                                                {offerState === "waiting" && <div className="driver-info-rows"><b>Driver is waiting</b><b style={{ fontSize: "10px" }}>5 mins remaining</b></div>}
                                                {offerState === "in_progress" && <div className="driver-info-rows"><b>In route</b><b style={{ fontSize: "10px" }}>You're 5 mins away from your destination</b></div>}

                                                <div className="driver-info-rows driver-card">
                                                    <div className="driver-profile" style={{ backgroundImage: `url(${offer.driver.profilePicture})`, border: "none" }}>
                                                        <img alt="car" src={offer.driver.car.image} className="car-image" />
                                                    </div>
                                                    <div className="driver-profile-information">
                                                        <b>{offer.driver.firstName}</b>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                            <span>{offer.driver.car.color} {offer.driver.car.model}</span>
                                                            <ion-icon name="ellipse"></ion-icon>
                                                            <span>{offer.driver.car.tagNumber}</span>
                                                        </div>
                                                    </div>
                                                    <button>
                                                        <ion-icon name="chatbubble-outline"></ion-icon>
                                                    </button>
                                                </div>





                                                {/* Pickup Input */}
                                                <div className="input-box" style={{ backgroundColor: "transparent", marginBottom: "5px" }}>
                                                    <span className="pl-circle">
                                                        <span className="connected-line"></span>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Pick up location"
                                                        value={pickup}
                                                        disabled={true}
                                                        onChange={(e) => {
                                                            setPickup(e.target.value);
                                                            fetchSuggestions(e.target.value, setPickupSuggestions);
                                                        }}

                                                    />
                                                </div>

                                                {/* Dropoff Input */}
                                                <div className="input-box" style={{ backgroundColor: "transparent" }}>
                                                    <span className="df-square"></span>
                                                    <input
                                                        type="text"
                                                        placeholder="Drop off location"
                                                        value={dropoff}
                                                        disabled={true}
                                                        onChange={(e) => {
                                                            setDropoff(e.target.value);
                                                            fetchSuggestions(e.target.value, setDropoffSuggestions);
                                                        }}
                                                    />
                                                </div>



                                                <div className="driver-info-rows driver-card">
                                                    <div className="driver-profile">
                                                        <ion-icon name="wallet-outline"></ion-icon>
                                                    </div>
                                                    <div className="driver-profile-information">
                                                        <b>**** **** **** 4623</b>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                            <span>Visa</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {offer && offer.offerOption && <b>${offer.offerOption.price}</b>}
                                                    </div>
                                                </div>
                                            </div>

                                            <button disabled={!offer} className="search-button" onClick={cancel}>cancel</button>
                                        </>
                                    )}

                                    {(offerState === "abandon" || offerState === "closed") && (
                                        <>
                                            <div className="searching">
                                                <ion-icon name="time-outline" style={{ fontSize: "42px" }}></ion-icon>
                                                <b>You're driver left</b>
                                                <b>You've been charge ${(Math.round(abandonFee * 1000) / 1000).toFixed(2)} for their time</b>
                                            </div>
                                            <button disabled={!offer} className="search-button" onClick={() => reset()}>Okay</button>
                                        </>
                                    )}
                                </> :
                                    <>
                                        <div className="searching">
                                            <ion-icon name="alert-circle-outline" style={{ fontSize: "42px" }}></ion-icon>
                                            <b>Insufficient Balance</b>
                                        </div>
                                        <button className="search-button" onClick={() => navigate("account")}>Add Cash</button>
                                    </>}
                            </div>
                        </div>


                        {/* Right: Map Section */}
                        <div className="ridersMapContainer" >
                            <Map
                                ref={mapRef}
                                mapboxAccessToken={apiToken}
                                initialViewState={viewport}
                                onMove={(evt) => setViewport(evt.viewState)}
                                style={{ width: width < 678 ? "100%" : "75%", height: width < 678 ? "100%" : "75%", borderRadius: "10px" }}
                                mapStyle="mapbox://styles/mapbox/streets-v11"
                            >
                                {/* Marker for User's Location */}

                                {route && (
                                    <Source id="route" type="geojson" data={{ type: "Feature", geometry: route }}>
                                        <Layer
                                            id="route-line"
                                            type="line"
                                            layout={{ "line-cap": "round", "line-join": "round" }}
                                            paint={{
                                                "line-color": "#000000",
                                                "line-width": 5,
                                            }}
                                        />
                                    </Source>
                                )}

                                {driverRoute && (
                                    <Source id="route" type="geojson" data={{ type: "Feature", geometry: driverRoute }}>
                                        <Layer
                                            id="route-line"
                                            type="line"
                                            layout={{ "line-cap": "round", "line-join": "round" }}
                                            paint={{
                                                "line-color": "#555555",
                                                "line-width": 5,
                                            }}
                                        />
                                    </Source>
                                )}

                                {userLocation && (
                                    <Marker
                                        longitude={userLocation[0]}
                                        latitude={userLocation[1]}
                                        anchor="bottom"
                                    >
                                        <img
                                            src="/pUser.png"
                                            alt="User Location"
                                            style={{ height: "30px", width: "30px" }}
                                        />
                                    </Marker>
                                )}

                                {pickupLocation && (
                                    <Marker
                                        longitude={pickupLocation[0]}
                                        latitude={pickupLocation[1]}
                                        anchor="bottom"
                                    >
                                        <img
                                            src="/pStart.png"
                                            alt="User Location"
                                            style={{ height: "30px", width: "30px" }}
                                        />
                                    </Marker>
                                )}


                                {dropoffLocation && (
                                    <Marker
                                        longitude={dropoffLocation[0]}
                                        latitude={dropoffLocation[1]}
                                        anchor="bottom"
                                    >
                                        <img
                                            src="/pEnd.png"
                                            alt="User Location"
                                            style={{ height: "30px", width: "30px" }}
                                        />
                                    </Marker>
                                )}

                                {offer && offer.driver && offer.driver.location && offerState === "accepted" && (
                                    <Marker
                                        longitude={offer.driver.location[0]}
                                        latitude={offer.driver.location[1]}
                                        anchor="bottom"
                                    >
                                        <img
                                            src="/pDriver.png"
                                            alt="User Location"
                                            style={{ height: "30px", width: "30px" }}
                                        />
                                    </Marker>
                                )}
                            </Map>
                        </div>
                    </div>
                    {tip && <TipBox onClose={() => setTip(false)} />}
                    {addingCard &&
                        <Elements stripe={stripePromise}>
                            <AddCard onClose={() => setAddingCard(false)} />
                        </Elements>
                    }
                </> :
                <div className="modal-container">
                    <div className="modal">
                        <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                            <ion-icon name="warning-outline" style={{ fontSize: "72px" }}></ion-icon>
                            <div>Please, Sign in before making any requests</div>
                            <button onClick={() => navigate("/login")}>Sign in</button>
                        </div>
                    </div>
                </div>}
        </>
    );
}



















function TipBox({ data, onClose = () => { } }) {
    const [error, setError] = useState("")
    const [customValue, setCustomValue] = useState(0)
    const tips = [5, 25, 50]

    const handleSubmit = () => {

    }


    return (
        <div className="modal-container" onClick={onClose}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
                <div className="header">
                    <b>Tip DriverName</b>
                    <span onClick={onClose}>
                        <ion-icon name="close-outline"></ion-icon>
                    </span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginLeft: "20px" }}>
                        {tips.map((tip, index) => (
                            <button key={index} onClick={(e) => e.preventDefault()}>
                                ${tip}.00
                            </button>
                        ))}
                    </div>
                    <div
                        style={{
                            overflow: "auto",
                            maxHeight: "60vh",
                            margin: "10px",
                            // display: "grid",
                            width: "unset",
                            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                            gap: "20px",
                            padding: "20px",
                            perspective: "500px",
                        }}
                    >
                        <InputBox
                            placeholder="First Name"
                            type="number"
                            value={customValue}
                            onChange={(e) => setCustomValue(e.target.value)}
                            name="firstName"
                        />

                    </div>

                    <p style={{ color: "red", fontSize: "12px", textAlign: "center" }}>{error}</p>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <button type="submit" className="submit-button">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function InputBox({ placeholder, value, onChange, name, type = "text" }) {
    return (
        <div className="input-box" style={{
            width: "150px"
        }}>
            <input

                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
            />
        </div>
    );
}

