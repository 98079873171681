import React, { useState } from "react";
import { useAuth } from "../AuthContext";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import './AddCard.css'

const serverUrl = process.env.REACT_APP_SERVER_URL;



const AddCard = ({ onClose = () => { } }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useAuth()

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            setMessage("Stripe has not loaded yet.");
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);

        // Create a payment method
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });

        if (error) {
            setMessage(`Error: ${error.message}`);
            setLoading(false);
            return;
        }

        // Send paymentMethod.id to your server
        const response = await fetch(serverUrl + "/add-card", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ uid: user.rider.uid, customerId: user.rider.stripeCustomerId, paymentMethodId: paymentMethod.id }),
        });

        const data = await response.json();

        if (data.success) {
            setMessage("Card added successfully!");
            onClose()
        } else {
            setMessage(`Error: ${data.message}`);
        }
        setLoading(false);
    };

    return (
        <form className="modal-container" onSubmit={handleSubmit} onClick={onClose}>
            <div className="modal CardElement" onClick={(e) => e.stopPropagation()}>
                <b style={{ marginBottom: "10px" }}>Add Card</b>

                <CardElement />
                {message && <p>{message}</p>}
                <button type="submit" disabled={!stripe || loading}>
                    {loading ? "Processing..." : "Add Card"}
                </button>
            </div>


        </form>
    );
};



export default AddCard;
















// try {
//     const response = await fetch(serverUrl + "/add-card", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//             cardNumber,
//             expMonth,
//             expYear,
//             cvc,
//             customerId: user.customer.id, // Replace with actual customer ID
//         }),
//     });

//     const data = await response.json();

//     if (data.success) {
//         setMessage("Card added successfully!");
//     } else {
//         setMessage(`Error: ${data.message}`);
//     }
// } catch (error) {
//     setMessage("Error processing the request.");
// } finally {
//     setLoading(false);
// }
// };