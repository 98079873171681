import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, database } from "./firebaseConfig"; // Firebase config
import { onAuthStateChanged, signOut } from "firebase/auth"; // Firebase methods
import { ref, get } from "firebase/database";

// Create the AuthContext
const AuthContext = createContext();

// Export useAuth hook for easy access to context
export const useAuth = () => {
    return useContext(AuthContext);
};

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
    const [isLoggedin, setLoggedin] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            console.log("hi");

            if (!firebaseUser) {
                // User is logged out
                console.log("no user");

                setUser(null);
                setLoggedin(false);
                setLoading(false);
                return;
            }


            try {
                // Fetch user data from Firebase Realtime Database
                const snapshot = await get(ref(database, `users/${firebaseUser.uid}`));
                if (snapshot.exists()) {
                    setUser(snapshot.val());
                    setLoggedin(true);
                } else {
                    console.log("No user data found");
                    setUser(null);
                    setLoggedin(false);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                logout();
            } finally {
                setLoading(false);
            }
        });

        // Clean up the listener on unmount
        return () => unsubscribe();
    }, []);

    // Function to log out
    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            setLoggedin(false);
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    // Context value to provide to the rest of the app
    const value = {
        isLoggedin,
        user,
        setUser,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : <Loading />} {/* Render loading state */}
        </AuthContext.Provider>
    );
};


function Loading() {

    return (
        <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span className="loading-indicator" />
        </div>
    )
}