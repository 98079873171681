import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../AuthContext";
import { v4 as uuidv4 } from "uuid";
import { createCustomer } from "../request";
import "./Login.css";

import AddCard from "../components/AddCard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";



const stripeKey = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripeKey);


export default function Signup() {
    const { setUser } = useAuth()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [addingCard, setAddingCard] = useState(false)

    const [step, setStep] = useState("profile")

    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();

        // Validate required fields
        if (!firstName || !lastName || !email || !password) {
            setErrorMessage("Please fill in all fields.");
            return;
        }

        try {

            // Firebase Authentication: Create user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const { customer } = await createCustomer(user.uid, { email, firstName, lastName })
            setAddingCard(true)
            setUser(customer.user)
            // Save user details in Realtime Database

            // navigate("/rider/account");
        } catch (error) {
            console.error("Signup Error:", error.message);
            setErrorMessage(error.message || "Signup failed.");
        }
    };


    const nextStep = (e) => {
        e.preventDefault()

        const stepSequence = [
            "profile",
            "account",
            "info"
        ]
        const nextStepIndex = stepSequence.findIndex(e => e === step) + 1
        setStep(stepSequence[nextStepIndex])
    }

    const prevStep = (e) => {
        e.preventDefault()

        if (step === "profile") return navigate("/login")

        const stepSequence = [
            "profile",
            "account",
            "info"
        ]
        const prevStepIndex = stepSequence.findIndex(e => e === step) - 1
        setStep(stepSequence[prevStepIndex])
    }

    return (
        <div className="login-page">
            <div className="login-container">
                <h1>Signup</h1>
                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <form onSubmit={handleSignup}>
                    {/* First Name */}
                    {step === "profile" &&
                        <>
                            <div className="input-group">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="Enter your first name"
                                    required
                                />
                            </div>

                            {/* Last Name */}
                            <div className="input-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder="Enter your last name"
                                    required
                                />
                            </div>
                        </>}

                    {/* Email */}
                    {step === "account" &&
                        <>
                            <div className="input-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>

                            {/* Password */}
                            <div className="input-group">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password"
                                    required
                                />
                            </div>
                        </>}

                    {step === "info" &&
                        <>
                            {/* Phone Number */}
                            <div className="input-group">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Enter your phone number"
                                />
                            </div>
                        </>}


                    <div>
                        <button onClick={prevStep}>Back</button>
                        {step !== "info" && <button onClick={nextStep}>Next</button>}

                        {step === "info" && <button type="submit" >Signup</button>}
                    </div>
                </form>

                <p className="register-text">
                    Already have an account? <a href="/login">Login here</a>.
                </p>
            </div>
            {addingCard &&
                <Elements stripe={stripePromise}>
                    <AddCard onClose={() => navigate("/")} />
                </Elements>
            }
        </div>
    );
}
