import React, { useEffect, useRef, useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useAuth } from "../AuthContext.js";
import { useNavigate, Navigate } from "react-router";
import { submitDriverForm, proccessDriverForm, uploadImage } from "../request.js";
import "./Driver.css";



export default function Driver() {
    const { user, setUser } = useAuth()
    const [step, setStep] = useState("createAccount");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        uid: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        dob: "",
        address: "",
        phone: "",
        licenseFront: "",
        licenseBack: "",
    });

    useEffect(() => {

        if (user && user.rider) {
            for (const field in formData) {
                handleInputChange(field, user.rider[field])
                setStep('addPhoneNumber')
            }
        }
    }, [user])

    useEffect(() => {
        if (!user) return
        const driver = user.driver

        if (!driver) return
        for (const field in formData) {
            handleInputChange(field, driver[field])
        }
        if (!driver.firstName || !driver.lastName || !driver.dob) {
            setStep("addName")
        } else if (!driver.address || !driver.phone) {
            setStep("addPhoneNumber")
        } else if (!driver.licenseFront || !driver.licenseBack) {
            setStep("addVehicleDetails")
        } else {
            setStep("finish")
        }
    }, [user])



    const handleInputChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleFileChange = async (field, file) => {
        const downloadURL = await uploadImage(`${user.driver.uid}/license`, file)
        console.log(downloadURL);
        setFormData((prev) => ({ ...prev, [field]: downloadURL }));
    };

    const nextStep = async () => {
        const stepOrder = [
            "createAccount",
            "addName",
            "addPhoneNumber",
            "addVehicleDetails",
            "finish"
        ];

        if (step === "createAccount") {
            if (!formData.email) {
                setError("Email is required");
                return;
            } else if (!formData.password) {
                setError("password is required");
                return;
            }
        } else if (step === "addName") {
            if (!formData.firstName) {
                setError("First name is required");
                return;
            } else if (!formData.lastName) {
                setError("Last name is required");
                return;
            } else if (!formData.dob) {
                setError("Date of birth is required");
                return;
            }
        } else if (step === "addVehicleDetails") {
            if (!formData.licenseFront) {
                setError("License Front is required");
                return;
            } else if (!formData.licenseBack) {
                setError("License Back is required");
                return;
            }
        }

        setError("")

        // Further validation checks for other steps can be added here...
        const newUser = await proccessDriverForm(formData)

        setUser(newUser.user)
        const currentIndex = stepOrder.indexOf(step);
        if (currentIndex < stepOrder.length - 1) {
            setStep(stepOrder[currentIndex + 1]);
        }

    };

    const prevStep = () => {
        const stepOrder = [
            "createAccount",
            "addName",
            "addPhoneNumber",
            "addVehicleDetails",
            "finish",
        ];
        const currentIndex = stepOrder.indexOf(step);
        if (currentIndex > 0) {
            setStep(stepOrder[currentIndex - 1]);
        }
    };

    const handleCreateAccount = async (onNext = () => { }) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                formData.email,
                formData.password
            );
            const user = userCredential.user;
            handleInputChange('uid', user.uid)
            handleInputChange('email', user.email)
            onNext()
        } catch (error) {
            const err = error.code ===
                "auth/email-already-in-use" ? "Email is already existed, Login to finish your driver proccess."
                : error.code === "auth/weak-password" ? "Password needs to be at least 6 characters"
                    : error.code
            setError(err);
        }
    };

    const handleSubmit = async () => {
        try {

            await submitDriverForm(user.driver.uid)

            // navigate(`/login`); // Navigate after successful submission
        } catch (error) {
            console.error("Error storing driver details", error);
            setError("Error submitting driver details.");
        }
    };

    if (user && user.driver && user.driver.status === "verified") {
        return <Navigate to={"dashboard"} />
    }

    return (
        <>
            <div className="header-background" style={{ margin: "0px" }}></div>
            <div className="driver-page">
                <section className="driver-applications">
                    <Form
                        step={step}
                        formData={formData}
                        onInputChange={handleInputChange}
                        onFileChange={handleFileChange}
                        onNext={nextStep}
                        onPrev={prevStep}
                        onCreateAccount={async () => await handleCreateAccount(nextStep)}
                        onFinish={async () => {
                            await handleSubmit()
                            navigate("dashboard")
                        }}
                        error={error}
                    />
                </section>
            </div>
        </>
    );
}

function Form({ step, formData, onInputChange, onFileChange, onNext, onPrev, onCreateAccount, onFinish, error }) {
    return (
        <div className="sign-up">
            {error && <p className="error">{error}</p>}
            {step === "createAccount" && (
                <>
                    <h1>Create an Account</h1>
                    <InputBox
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => onInputChange("email", e.target.value)}
                    />
                    <InputBox
                        placeholder="Password"
                        type="password"
                        value={formData.password}
                        onChange={(e) => onInputChange("password", e.target.value)}
                    />
                    <button onClick={onCreateAccount}>Next</button>
                </>
            )}
            {step === "addName" && (
                <>
                    <h1>Add Your Name & DOB</h1>
                    <InputBox
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={(e) => onInputChange("firstName", e.target.value)}
                    />
                    <InputBox
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={(e) => onInputChange("lastName", e.target.value)}
                    />
                    <InputBox
                        placeholder="Date of Birth (YYYY-MM-DD)"
                        type="date"
                        value={formData.dob}
                        onChange={(e) => onInputChange("dob", e.target.value)}
                    />
                    <div style={{ display: "flex" }}>
                        <button onClick={onPrev}>Back</button>
                        <button onClick={onNext}>Next</button>
                    </div>
                </>
            )}
            {step === "addPhoneNumber" && (
                <>
                    <h1>Address & Phone Number</h1>
                    <InputBox
                        placeholder="Address"
                        value={formData.address}
                        onChange={(e) => onInputChange("address", e.target.value)}
                    />
                    <InputBox
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={(e) => onInputChange("phone", e.target.value)}
                    />
                    <div style={{ display: "flex" }}>
                        <button onClick={onPrev}>Back</button>
                        <button onClick={onNext}>Next</button>
                    </div>
                </>
            )}
            {step === "addVehicleDetails" && (
                <>
                    <h1>Upload Your Ids</h1>
                    {/* <InputBox
                        placeholder="Car Model"
                        value={formData.carModel}
                        onChange={(e) => onInputChange("carModel", e.target.value)}
                    />
                    <InputBox
                        placeholder="Car Color"
                        value={formData.carColor}
                        onChange={(e) => onInputChange("carColor", e.target.value)}
                    />
                    <InputBox
                        placeholder="Tag Number"
                        value={formData.tagNumber}
                        onChange={(e) => onInputChange("tagNumber", e.target.value)}
                    /> */}
                    <div style={{ display: "flex" }} >
                        <FileInput
                            label="Upload License Front"
                            id="lf"
                            value={formData.licenseFront}
                            onChange={(e) => onFileChange("licenseFront", e.target.files[0])}
                        />
                        <FileInput
                            id="lb"
                            label="Upload License Back"
                            value={formData.licenseBack}
                            onChange={(e) => onFileChange("licenseBack", e.target.files[0])}
                        />
                    </div>
                    <p>Make sure we can see all of your information from that ID.</p>

                    <div style={{ display: "flex" }}>
                        <button onClick={onPrev}>Back</button>
                        <button onClick={onNext}>Next</button>
                    </div>
                </>
            )}
            {step === "finish" && (
                <>
                    <h1>Finish</h1>
                    <p>Congrats, You have completed the driver proccess, now seatback and wait for your approval.</p>
                    <p>Once the status on your dashboard is "verified" you are good to procceed</p>
                    <video src="" controls />
                    <p>Watch this video to get ahead, learn how to setup you account so you can get into the driver seat.</p>
                    <p style={{ color: "red" }}>Important: Make sure you click Finish at the bottom so we can know you finish with your application.</p>
                    <div style={{ display: "flex" }}>
                        <button onClick={onPrev}>Back</button>
                        <button onClick={onFinish}>Finish</button>
                    </div>
                </>
            )}
        </div>
    );
}

function InputBox({ placeholder, value, onChange, type = "text" }) {
    return (
        <div className="input-box">
            <input type={type} placeholder={placeholder} value={value} onChange={onChange} />
        </div>
    );
}

function FileInput({ label, id, value = "", onChange }) {
    const fileRef = useRef(null);
    const [file, setFile] = useState(null);



    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }

        // Trigger the onChange callback with the file
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className="file-input">
            <label
                style={{
                    width: "200px",
                    aspectRatio: "5 / 3",
                    border: "1px solid #0005",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    backgroundImage: value ? `url(${value})` : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "10px",
                    margin: "5px"
                }}

                htmlFor={id}
            >
                {!value && label}
            </label>
            <input
                id={id}
                ref={fileRef}
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the input visually
            />
        </div>
    );
}