import { useEffect, useState } from "react";
import { useAuth } from "../AuthContext";
import { useNavigate, useLocation } from "react-router";
import { updateProfileForm, uploadImage } from "../request";

export default function ProfileModal() {
    const location = useLocation()
    const navigate = useNavigate()
    const { user, setUser } = useAuth();
    const accountType = location.pathname.includes("driver") ? "driver" : "rider";
    const [message, setMessage] = useState("Profile is up to date");
    const [canUpdate, setCanUpdate] = useState(false)
    const width = window.innerWidth
    const [formData, setFormData] = useState({
        uid: "",
        profilePicture: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    });

    console.log(location.pathname);

    useEffect(() => {
        if (!user) return;

        const profile = user[accountType];
        if (!profile) {
            setMessage("Profile doesn't exist");
            return;
        }

        setFormData({
            uid: profile.uid,
            profilePicture: profile.profilePicture || "",
            firstName: profile.firstName || "",
            lastName: profile.lastName || "",
            email: profile.email || "",
            phone: profile.phone || ""
        });
    }, [user]);

    const handleInputChange = (key) => (e) => {
        setFormData((prevData) => ({ ...prevData, [key]: e.target.value }));
    };

    const handleSave = async () => {
        if (!user) return;

        const newUser = await updateProfileForm(accountType, formData)
        console.log(newUser);
        setUser(newUser.user);
        setMessage("Profile updated successfully!");
        navigate(-1)
    };

    const handleDelete = () => {
        if (window.confirm("Are you sure you want to delete your profile?")) {
            setUser(null);
            setMessage("Profile deleted");
        }
    };

    useEffect(() => {
        if (!user) return;

        const profile = user[accountType];
        if (!profile) {
            setMessage("Profile doesn't exist");
            return;
        }

        // Compare formData with profile data
        const hasDifferences = Object.keys(formData).some(
            (field) => formData[field] !== profile[field]
        );

        if (hasDifferences) {
            setMessage("Profile is not up to date");
            setCanUpdate(true)
        } else {
            setMessage("Profile is up to date");
            setCanUpdate(false)
        }
    }, [user, formData, accountType]);


    if (!user) return <p>{message}</p>;

    return (
        <div className="modal-container">
            <div className="modal" style={{ width: width < 678 ? "100vw " : "500px", height: width < 678 ? "100vh " : "500px", maxHeight: width < 678 ? "100vh " : "unset" }}>
                <div className="content-pr" style={{ height: width < 678 ? "100vh " : "500px", maxHeight: width < 678 ? "100vh " : "unset" }}>
                    <div className="pr-header">
                        <label htmlFor="profileInput"
                            className="pr-picture"
                            style={{
                                backgroundImage: `url(${formData.profilePicture})`,
                                cursor: "pointer"
                            }}
                        />
                        <input type="file" id="profileInput" style={{ display: "none" }} onChange={async (e) => {
                            // const result = await uploadProfileImage(user.userId, e.target.files[0])
                            const downloadURL = await uploadImage(`${user[accountType].uid}/profile/`, e.target.files[0])
                            console.log(downloadURL);
                            setFormData((prev) => ({ ...prev, profilePicture: downloadURL }))
                            e.target.value = ""
                        }} />
                    </div>
                    <div className="pr-about">
                        <h2>{formData.firstName} {formData.lastName}</h2>
                        <p>{formData.email}</p>
                    </div>
                    <hr />
                    <InputsBox
                        title="Name"
                        inputPattern={[
                            { type: "text", placeholder: "First Name", value: formData.firstName, onChange: handleInputChange("firstName") },
                            { type: "text", placeholder: "Last Name", value: formData.lastName, onChange: handleInputChange("lastName") }
                        ]}
                    />
                    <hr />
                    <InputsBox
                        title="E-mail"
                        inputPattern={[
                            { type: "email", placeholder: "E-mail", value: formData.email, onChange: handleInputChange("email") }
                        ]}
                    />
                    <hr />
                    <InputsBox
                        title="Phone"
                        inputPattern={[
                            { type: "number", placeholder: "Phone", value: formData.phone, onChange: handleInputChange("phone") }
                        ]}
                    />
                    <hr />
                    <p style={{ textAlign: "center" }}>{message}</p>
                    <div style={{ padding: "10px 25px", display: "flex", justifyContent: "flex-end" }}>
                        <button style={{ marginRight: "auto" }} className="stop" onClick={handleDelete}>
                            Delete Profile
                        </button>
                        <button onClick={() => navigate(-1)}>Close</button>
                        <button onClick={handleSave} disabled={!canUpdate}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function InputsBox({ title = "Name", inputPattern }) {

    const width = window.innerWidth

    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", padding: "10px 25px" }}>
            <b style={{ marginRight: "auto" }}>{title}</b>
            <div className="input-box">
                {inputPattern.map((input, index) => (
                    <input
                        style={{ fontSize: "12px" }}
                        key={index}
                        type={input.type}
                        placeholder={input.placeholder}
                        value={input.value}
                        onChange={input.onChange}
                    />
                ))}
            </div>
        </div>
    );
}
