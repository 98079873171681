import './App.css';
import React, { useEffect, useRef } from 'react';
import Header from './components/Header';
import Map from './components/Map';
import Main from './pages/Main';
import Footer from './components/Footer';
import About from './pages/About';
import Driver from './pages/Driver';
import Rider from './pages/Ride';
import Login from './pages/Login';
import Signup from './pages/Signup';
import DriverDashboard from './pages/DriversPage';
import RidersDashboard from './pages/RidersPage';
import ProfileModal from './components/ProfileModal'
import ChangePassword from './pages/ChangePassword';
import Admin from './pages/AdminPage';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router';
import { useAuth } from "./AuthContext";


function App() {
  const location = useLocation();


  // Define routes where Header and Footer should not appear
  const showHeaderFooterRoutes = ['/', '/rider', '/about', '/driver'];
  const shouldShowHeaderFooter = showHeaderFooterRoutes.includes(location.pathname);
  return (
    <div className="App">
      {shouldShowHeaderFooter && <Header />}
      <div style={{ minHeight: "100vh" }}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/driver' element={<Driver />} />
          <Route path='/driver/edit-profile' element={<ProfileModal />} />
          <Route path='/driver/*' element={<DriverDashboard />} />
          <Route path='/rider/edit-profile' element={<ProfileModal />} />
          <Route path='rider/*' element={<RidersDashboard />} />
          <Route path='/rider' element={<Rider />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/change-password' element={<ChangePassword />} />

          <Route path='/admin' element={<Admin />} />
          <Route path='/*' element={<Navigate to={"/"} />} />
        </Routes>
      </div>
      {shouldShowHeaderFooter && <Footer />}
    </div>
  );
}

const Home = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default App;



