import React, { useEffect, useState } from "react";
import { auth } from "../firebaseConfig"; // Firebase configuration file
import { signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router";

export default function Login() {

    const { user } = useAuth()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            console.log(user);
            if (user.rider) navigate("/rider/account")
        }
    }, [user, navigate])

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setErrorMessage("Please fill in all fields.");
            return;
        }

        try {
            // Firebase Authentication
            await signInWithEmailAndPassword(auth, email, password);
            setErrorMessage(""); // Clear error
            navigate("/")
        } catch (error) {
            // Firebase error handling
            console.error("Login Error:", error.message);
            setErrorMessage("Invalid email or password.");
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h1>Login</h1>
                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <form onSubmit={handleLogin}>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <button onClick={(e) => {
                        e.preventDefault();
                        navigate("/")
                    }} >Back</button>
                    <button type="submit" >Login</button>
                </form>

                <p className="register-text">
                    Don't have an account? <a href="/signup">sign up here</a>.
                </p>
            </div>
        </div>
    );
}
