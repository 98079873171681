import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Map from "../components/Map";
import "../App.css"

const apiToken = process.env.REACT_APP_MAPBOX_TOKEN;


const services = [
    {
        image: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1728269131543x437875590634415600%2FAI-Generated-Image.png?w=1209.8666666666666&h=832&auto=compress&dpr=2&=&fit=crop&crop=entropy",
        title: "Eco-Friendly Commutes",
        subtitle: "Join the movement for a sustainable planet with our Eco-Friendly Commutes initiative.",
        link: "/"
    },
    {
        image: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1728269126324x654707781202760800%2FAI-Generated-Image.png?w=1209.8666666666666&h=832&auto=compress&dpr=2&=&fit=crop&crop=entropy",
        title: "Convenient Access",
        subtitle: "Experience seamless travel planning with our Convenient Access feature, simplifying your journeys.",
        link: "/"
    },
    {
        image: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1728269120953x174727948185964260%2FAI-Generated-Image.png?w=1209.8666666666666&h=832&auto=compress&dpr=2&=&fit=crop&crop=entropy",
        title: "Affordable Rides",
        subtitle: "Affordable Rides connects you to vital destinations smoothly and safely.",
        link: "/"
    }
]

const discovers = [
    {
        image: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1732556249390x615083677024987000%2FAI-Generated-Image.png?w=1209.8666666666666&h=832&auto=compress&dpr=2&=&fit=crop&crop=entropy",
        title: "Transparent Earnings",
        subtitle: "Implement smart strategies to maximize profits using advanced real-time data and analytics.",
        link: "/"
    },
    {
        image: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1732556244180x673666699024398500%2FAI-Generated-Image.png?w=1209.8666666666666&h=832&auto=compress&dpr=2&=&fit=crop&crop=entropy",
        title: "Driver Incentives",
        subtitle: "Earn reward points and cash bonuses by achieving driving milestones and completing challenges.",
        link: "/"
    },
    {
        image: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fd1muf25xaso8hp.cloudfront.net%2Fhttps%3A%2F%2F4b9010a12fdc79aa397529954aaa1531.cdn.bubble.io%2Ff1732556239297x472413013276691260%2FAI-Generated-Image.png?w=1209.8666666666666&h=832&auto=compress&dpr=2&=&fit=crop&crop=entropy",
        title: "Profit Boost",
        subtitle: "See your daily, weekly, and monthly earnings clearly with detailed breakdowns for transparency.",
        link: "/"
    }
]

export default function Main() {
    const [userLocation, setUserLocation] = useState(null); // Store user's coordinates
    const [pickup, setPickup] = useState("");
    const [dropoff, setDropoff] = useState("");
    const [pickupSuggestions, setPickupSuggestions] = useState([]);
    const [dropoffSuggestions, setDropoffSuggestions] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {
        // Fetch user's location on load
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([longitude, latitude]);
                },
                (error) => {
                    console.error("Error fetching user location:", error);
                }
            );
        } else {
            console.error("Geolocation is not available");
        }
    }, []);

    const fetchSuggestions = (query, setSuggestions) => {
        if (!query || !userLocation) {
            setSuggestions([]);
            return;
        }

        const [longitude, latitude] = userLocation;
        fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${apiToken}&autocomplete=true&proximity=${longitude},${latitude}`
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.features) {
                    setSuggestions(data.features.map((feature) => feature.place_name));
                }
            })
            .catch((error) => console.error("Error fetching suggestions:", error));
    };

    return (
        <>
            <div className="main">
                <Map />
                <div className="main-hero" id="home">
                    <b style={{ marginTop: "300px" }}>#1 in Community Transportation</b>
                    <h1>Ridesharing for a Better Community</h1>
                    <p>As recognized by trusted community services and local partners.</p>

                </div>
            </div>

            {/* <div className="header-background"></div> */}

            <div className="rider-section" id="ride">
                <div className="rider-input-container">
                    <h2 style={{ textAlign: "center" }}>Empowering Communities with Affordable Rides</h2>

                    {/* Pickup Input */}
                    <div className="input-box">
                        <span className="pl-circle">
                            <span className="connected-line"></span>
                        </span>
                        <input
                            type="text"
                            placeholder="Pick up location"
                            value={pickup}
                            onChange={(e) => {
                                setPickup(e.target.value);
                                fetchSuggestions(e.target.value, setPickupSuggestions);
                            }}
                        />
                        {/* Pickup Suggestions */}
                        {pickupSuggestions.length > 0 && (
                            <ul className="suggestions-list">
                                {pickupSuggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            setPickup(suggestion);
                                            setPickupSuggestions([]);
                                        }}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* Dropoff Input */}
                    <div className="input-box">
                        <span className="df-square"></span>
                        <input
                            type="text"
                            placeholder="Drop off location"
                            value={dropoff}
                            onChange={(e) => {
                                setDropoff(e.target.value);
                                fetchSuggestions(e.target.value, setDropoffSuggestions);
                            }}
                        />
                        {/* Dropoff Suggestions */}
                        {dropoffSuggestions.length > 0 && (
                            <ul className="suggestions-list">
                                {dropoffSuggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            setDropoff(suggestion);
                                            setDropoffSuggestions([]);
                                        }}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <button className="search-button" onClick={() => navigate("/rider")}>Search</button>
                </div>
            </div>

            <div className="section" id="services">
                <div style={{ fontSize: "24px", padding: "0px 70px" }}>Discover seamless community transportation at unmatched rates.</div>

                <div className="card-rows">
                    {services.map((service, index) => (
                        <div className="card" key={index}>
                            <img src={service.image}
                                alt={service.title}
                            />
                            <h2>{service.title}</h2>
                            <p>{service.subtitle}</p>
                            <a href={service.link} >Learn More</a>
                        </div>
                    ))}



                </div>
            </div>

            <div className="section" id="services">
                <div style={{ fontSize: "24px", padding: "0px 70px" }}>Discover New Routes and Earnings.</div>
                <div style={{ padding: "0px 70px" }}>Unlock better driving opportunities with flexible options tailored for you.
                </div>

                <div className="card-rows">
                    {discovers.map((service, index) => (
                        <div className="card" key={index}>
                            <img src={service.image}
                                alt={service.title}
                            />
                            <h2>{service.title}</h2>
                            <p>{service.subtitle}</p>
                            <a href={service.link} >Learn More</a>
                        </div>
                    ))}



                </div>
            </div>
        </>
    );
}
